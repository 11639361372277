import { RawList } from 'spc/lib/database/types/list';
import { ApiService } from 'spc/shared/api/api.service';
import { RawBaseUser } from 'spc/lib/database/types/base-user';
import { UserService } from 'spc/services/user.service';
import debounce from 'lodash/debounce';

class ListCardViewController {
  user: RawBaseUser;
  userId: string;
  lists: RawList[];
  archivedLists: RawList[];
  hasArchived: boolean = false;
  currentPage: number;
  totalLists: number;
  listCreators: any;
  selectedCreators: string[];
  filters: {
    creator: string[],
    sort: any,
    text: string
  } = { creator: [], sort: '', text: '' };
  creators: string[];
  sortParameters: { key: string, value: {} }[] =  [
    { key: 'Name (A-Z)', value: { name: 1 } },
    { key: 'Name (Z-A)', value: { name: -1 } },
    { key: 'Created (Newest)', value: { createdAt: -1 } },
    { key: 'Created (Oldest)', value: { createdAt: 1 } }];
  loading: boolean = false;


  constructor(
    private $user: UserService,
    private $api: ApiService,
    private unwrapError,
    private $scope,
    private $clickOk,
    private $cloudinary,
  ) {
    'ngInject';
  }

  $onInit = () => {
    this.user = this.$user.$;
    this.userId = this.user._id.toString();
    this.loading = true;
    this.fetchAccountsLists();
  }

  fetchAccountsLists(idx = 0) {
    this.currentPage = idx + 1;
    this.loading = true;
    this.lists = [];
    this.$api.Lists.getAccountLists({ userId: this.userId, page: idx, filters: this.filters })
      .then((res: any) => {
        this.lists = res.lists.data;
        this.creators = res.lists.creators;
        this.archivedLists = res.lists.archivedData;
        this.totalLists = res.lists.totalListCount;
        this.loading = false;
      })
      .catch(error => this.unwrapError(error));
  }
  debouncedListSearch = debounce(this.fetchAccountsLists, 600);

  noFilterApplied = () => !this.filters.creator && !this.filters.text;

  isCreatorSelected = (creator) => {
    return this.filters.creator.includes(creator);
  }

  setSorting = (param) => {
    this.filters.sort = param.value;
    this.fetchAccountsLists();
  }
  setCreatorFilter = (creator) => {
    if (!this.isCreatorSelected(creator)) {
      this.filters.creator.push(creator);
    }
    else {
      this.filters.creator = this.filters.creator.filter((element) => {
        return element !== creator;
      });
    }
    this.fetchAccountsLists();
  }

}

export const TeamListCardViewComponent = {
  template: require('./team-list-card-view.component.jade'),
  controller: ListCardViewController,
};
