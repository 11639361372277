// NPM Deps
import { primaryEventContact, allowedToView } from 'common/dist/virtuals/AuthorizedClient';

// Our Dependencies
import { ApiService } from 'spc/shared/api/api.service';
import { IContact } from 'spc/shared/contact.constant';
import { MessageService } from 'spc/requests/messages/message.service';

class TopLevelConversationMessagesController {
  bookingRequest: any;
  conversation: any;
  venue: any;
  messages: any[];
  tab: string;
  stateManager: { isClient?: boolean, isAdmin?: boolean, isInquiry?: boolean; loading: boolean, getMessagesInterval?: any, canMessage?: boolean } = { loading: true };
  userId: string;
  user: any;

  constructor(private $routeParams,
    private $location: ng.ILocationService,
    private $rootScope: ng.IRootScopeService,
    private $scope,
    private $api: ApiService,
    private unwrapError,
    private $user,
    private $window,
    private $interval: ng.IIntervalService,
    private CONTACT: IContact,
    private messageService: MessageService,
    private $cloudinary,
    private recoRequestModal,
    private leadAuthenticationModal ) {
    'ngInject';
    this.userId = $routeParams.userId;
  }

  $onInit = () => {

    this.$scope.$broadcast('START_REQUEST_LISTENER');
    this.initialize()
    .catch(error => this.handleHttpError(error));
    this.tab = 'REVIEW';
  }

  $onDestroy = () => {
    if (this.stateManager.getMessagesInterval) {
      this.$interval.cancel(this.stateManager.getMessagesInterval);
      this.stateManager.getMessagesInterval = undefined;
    }
  }

  loading = () => {
    this.stateManager.loading = true;
  }

  loaded = () => {
    this.stateManager.loading = false;
  }

  sendMessage = (message: string): ng.IPromise<void> => {
    let baseUser;
    if (this.userId) {
      baseUser = this.conversation.clients.find(client => client.user._id.toString() === this.userId).user;
    }
    return this.$api.Conversations
      .sendMessage({ data: { message }, conversation: this.conversation, baseUser })
      .then(data => this.addMessage(data.message));
  }

  initialize = (): ng.IPromise<any> => {
    return this
      .getConversation(this.$routeParams.id)
      .then(() => {
        this.user = this.$user.$ || null;
        // if (this.shouldAddUser()) {
        //   this.addUser();
        // }

        this.stateManager.isAdmin = this.isAdmin();
        this.stateManager.isClient = this.isClient();
        this.stateManager.canMessage = this.canMessage();
        this.stateManager.isInquiry = this.isInquiry();

        if (this.shouldRedirect()) {
          this.rerouteToConversationPage(this.conversation);
        }

        this.setMessagesInterval();
        this.$rootScope.$emit('$viewReady', 'HIDE_FOOTER');
      })
      .then(() => {
        if (this.$user.isLoggedIn() && this.canMessage()) {
          this.messageService.markConversationRead({ conversation: this.conversation });
        }
      }).catch((error) => {
        this.redirect(`/unauthorized`);
        this.unwrapError(error);
      });
  }

  getConversation = (conversationId: string): ng.IPromise<void> => {
    return this.$api.Conversations
      .getConversationMessages(conversationId, { user: this.userId })
      .then(data => this.handleGetResponse(data));
  }

  isClient = (): boolean => {
    return !this.user || !this.isVenueUser();
  }

  canMessage = (): boolean => {
    if (this.isAdmin()) {
      return true;
    }

    return this.isAuthClient() || this.isVenueUser();
  }

  isAuthClient = (): boolean => {
    return this.user && this.conversation.clients.some(client => client.user._id === this.user._id);
  }

  isVenueUser = (): boolean => {
    return this.user && this.venue.authorizedUsers.some(host => host.user._id === this.user._id);
  }

  getPrimaryClient = (conversation): any => {
    return primaryEventContact(conversation).user;
  }

  isAdmin = () => {
    return this.$user.isAdmin();
  }

  handleGetResponse = (data: { conversation: any, messages: any[], venue?: any, bookingRequest?: any, redirect?: any }): void => {
    if (data.bookingRequest) {
      this.bookingRequest = data.bookingRequest;
    }
    this.conversation = data.conversation;
    this.messages = data.messages;
    this.venue = data.venue;
    this.loaded();
  }

  createBookingRequest = () => {
    return this.recoRequestModal({ origin: 'conversation', venue: this.venue, conversation: this.conversation })
      .then(data => this.handleRecoRequest(data))
      .catch(error => this.unwrapError(error));
  }

  continueBookingRequest = () => {
    return this.recoRequestModal({ origin: 'conversation-continued', venue: this.venue, conversation: this.conversation, request: this.bookingRequest })
      .then(data => this.handleRecoRequest(data))
      .catch(error => this.unwrapError(error));
  }

  handleRecoRequest = (data) => {
    const request = data.value.request;
    if (request.state && request.state !== 'INCOMPLETE') {
      return this.rerouteToConversationPage(this.conversation);
    }

    if (request && request._id) {
      this.bookingRequest = request;
    }
  }

  redirect = (url: string) => this.$location.url(url);

  isInquiry = () => this.conversation && (!this.conversation.request || this.conversation.requestState === 'INCOMPLETE');

  nonDeletedAttachments = (conversation) => {
    if (conversation.attachments && conversation.attachments.length) {
      const conversationAttachments = conversation.attachments.filter(attachment => !attachment.isDeleted).length;
      return conversationAttachments;
    }
  }
  shouldRedirect = () => {
    if (this.stateManager.isClient) {
      return this.conversation.request && !['INCOMPLETE', 'PROPOSAL'].includes(this.conversation.requestState);
    } else {
      return this.conversation.request && !['INCOMPLETE'].includes(this.conversation.requestState);
    }
  }

  cancel = (message) => {
    this.$api.Requests.guestCancel(this.bookingRequest._id, message)
          .then((res) => {
            this.bookingRequest = Object.assign(res.data.request, { clients: this.bookingRequest.clients });
            this.$location.path(`/client/conversation/${this.bookingRequest.conversation}`);

          }).catch(error => this.unwrapError(error));
  }

  rerouteToConversationPage = (conversation) => {
    let redirectUrl = '';
    if (this.stateManager.isClient) {
      redirectUrl = `/client/conversation/${ conversation._id }`;
      if (this.userId) {
        redirectUrl = redirectUrl.concat(`/preview/`);
      }
    } else {
      redirectUrl = `/venue/conversation/${conversation._id}`;
    }
    return this.redirect(redirectUrl);
  }

  setMessagesInterval() {
    this.stateManager.getMessagesInterval = this.$interval(() => {
      this.$api.Conversations
        .getConversationMessages(this.conversation._id, { user: this.userId })
        .then((data) => {
          const { messages } = data;
          this.messages = messages;
        })
        .catch(error => this.handleHttpError(error));
    }, 1000 * 30);
  }

  addMessage = (message: string): void => {
    this.messages.unshift(message);
  }

  handleHttpError = (error: Error) => {
    if (this.stateManager.loading) {
      this.loaded();
    }
    this.unwrapError(error);
  }

  // shouldAddUser = (): Boolean => {
  //   return this.user && !this.isVenueUser() && !this.isAdmin() && !this.isAuthClient();
  // }

  // addUser = (): void  => {
  //   this.$api.Conversations.addUserToConversation(this.conversation._id)
  //   .then((data) => {
  //     this.conversation = data.conversation;
  //     this.bookingRequest = data.bookingRequest;
  //     this.stateManager.canMessage = this.canMessage();
  //     this.$scope.$broadcast('USER_ADDED', { message: 'You have joined this conversation.' });
  //   }).catch(error => this.unwrapError(error));
  // }

  logIn = () => {
    return this.leadAuthenticationModal(this.bookingRequest)
      .then((res) => {
        this.user = this.$user.$ || null;
        this.stateManager.canMessage = this.canMessage();
        // if (this.shouldAddUser()) {
        //   return this.addUser();
        // }
      })
      .catch(error => this.unwrapError(error));
  }
}

export const TopLevelConversationMessagesComponent = {
  controller: TopLevelConversationMessagesController,
  template: require('./top-level-conversation-messages.component.jade')
};
