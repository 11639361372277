import moment from 'moment';
import get from 'lodash/get';
import { includes } from 'lodash';
import { DUser } from 'spc/lib/database/types/user';
import { DAccount } from 'spc/lib/database/types/accounts';
import { DBaseUser } from 'spc/lib/database/types/base-user';
import { DateOfPayment, AmountPaid, PaymentConversation, ReceiptResponse } from 'spc/lib/server/api/leads/models';
import { DBookingRequest } from 'spc/lib/database/types/booking-request';
import { RawLead } from 'spc/lib/database/types/lead';
import { CityTzMapperService } from 'spc/shared/cityTZmapper.service';
interface Link {
  name: string;
}
class ConciergeRequestsDashboardController {
  componentIsReady: () => any;
  user: DUser;
  members: DBaseUser[];
  bookings: DBookingRequest[];
  hasAccount: boolean = false;
  confirmedLeads: RawLead[] = [];
  activeLeads: RawLead[] = [];
  concludedLeads: RawLead[] = [];
  inactiveLeads: RawLead[] = [];
  events: any[] = [];
  links: Link[];
  displayTab: string;
  receipts: ReceiptResponse[];
  componentView: string = 'Card View';
  stateManager: { tab: string; };
  allLeads: RawLead[] = [];
  ui: {
    loading: boolean
  } = {
      loading: false
    };

  constructor(
    private $api,
    private ENUMS,
    private createEditEventModal,
    $scope,
    private $window,
    private $user,
    private unwrapError,
    private cityTzMapperService: CityTzMapperService
  ) {
    'ngInject';
    $scope.$on('CHANGE_PARENT_STATE', function (event, args) {
      $scope.currentState = args.state;
    });

    $scope.isState = function isState(state) {
      return $scope.currentState === state;
    };

    $scope.$on('CHANGE_PARENT_STATE', function (event, args) {
      $scope.currentState = args.state;
    });
  }

  $onInit = () => {
    this.user = !!this.$user.$ ? this.$user.$ : null;
    if (this.user.account) {
      this.accountMembershipDetails();
      this.hasAccount = true;
    }
    this.ui.loading = true;
    this.$api.Leads.getUserLeads()
      .then(response => this.setLeads(response))
      .then(() => { this.componentIsReady(); this.ui.loading = false; });

    this.links = [
      { name: 'Your Events' },
    ];
    if (this.hasAccount) {
      this.links.push({ name: 'Team Events' });
    }
    this.stateManager = {
      tab: 'Card View'
    };
  }

  changeTab = (viewType) => {
    if (!includes(['Card View', 'Table View'], viewType)) {
      this.unwrapError(new Error(`Invalid view type ${viewType}`));
    }
    if (this.stateManager.tab && viewType === this.stateManager.tab) {
      return;
    } else {
      return this.stateManager.tab = viewType;
    }
  }

  openLeadPage = (lead) => {
    const url = `/recos/${lead._id.toString()}`;
    window.open(url);
  }

  accountMembershipDetails = () => {
    return this.$api.MembershipAccount.getAccount()
      .then(res => this.members = res.account.members as DBaseUser[])
      .catch((error) => {
        this.unwrapError(error);
      });
  }

  openCreateEditEventModal() {
    return this.createEditEventModal({})
      .then((res) => {
        if (res.value.data.lead) {
          this.$window.location.reload();
        }
      });
  }

  setLeads = (response) => {
    const events = response.sharedEvents;
    events.forEach(event => event.isShared = true);
    if (events.length) {
      response.leads = response.leads.concat(events);
    }
    const leads = response.leads;
    this.allLeads = leads;
    this.displayTab = 'Active';
    leads.forEach((lead) => {
      const bookedReco = lead.recommendations.find(reco => this.isBookedEvent(get(reco, 'conversation.request')));
      lead.bookedReco = bookedReco;
      const cancelledReco = lead.recommendations.length ? lead.recommendations.every(reco => this.isCancelledEvent(get(reco, 'conversation.request'))) : false;
      if (bookedReco) {
        lead.eventDate = moment(bookedReco.conversation.request.data.date).local();
      } else if (lead.request.date) {
        const tz = this.cityTzMapperService.getCityTimezone(lead.request.city);
        lead.eventDate = moment.tz(lead.request.date, tz);
      }
      const yesterday = moment().subtract(1, 'day');
      // lead.eventDate = this.isBookedEvent(event)
      if (['Lost', 'Killed'].includes(lead.currentStatus)) {
        this.inactiveLeads.push(lead);
      }
      else if (lead.eventDate && bookedReco) {
        this.confirmedLeads.push(lead);
      } else if (lead.eventDate > yesterday && !bookedReco && !cancelledReco) {
        this.activeLeads.push(lead);
      } else if (!lead.eventDate) {
        this.activeLeads.push(lead);
      } else {
        this.inactiveLeads.push(lead);
      }
    });
  }


  isCancelledEvent = (event) => {
    if (!event) {
      return;
    }
    return this.ENUMS.bookingRequestState.cancelledStates.includes(event.state);
  }

  isBookedEvent = (event) => {
    if (!event) {
      return;
    }
    return this.ENUMS.bookingRequestState.bookedStates.includes(event.state);
  }
}

export const ConciergeRequestsDashboardComponent = {
  controller: ConciergeRequestsDashboardController,
  template: require('./concierge-requests-dashboard.component.jade'),
  bindings: {
    componentIsReady: '&',

  }
};
