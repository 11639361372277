import { RawLead } from 'spc/lib/database/types/lead';
import { DBaseUser } from 'spc/lib/database/types/base-user';
import { AccountMemberLeadsDetailsService } from './account-member-leads-details.service';
import { unset } from 'lodash';
import _ from 'lodash';

interface MembersArray {
  member: DBaseUser;
  checked: boolean;
}
class AccountMembersTableViewController {
  leads: RawLead[];
  currentPage: number;
  members: DBaseUser[];
  numLeads: number = 0;
  cities: string[] = [];
  activeLeads: RawLead[];
  inactiveLeads: RawLead[];
  confirmedLeads: RawLead[];
  concludedLeads: RawLead[];
  viewType: string = 'Table View';
  currentPageLeads: number  = 0;
  loading: boolean = true;
  tableViewLeadsPerPage: number = 15;
  search: {
    city?: string,
    currentStatuses?: string[],
    owner?: string[],
    primaryClients?: string[]
    eventdate?: any
  } = {};
  ui: {
    displaySidebar: boolean
  } = { displaySidebar: false };

  isOwnerFilter: Boolean = false;
  isStatusesFilter: Boolean = false;
  isCityFilter: Boolean = false;
  isEventDateFilter: Boolean = false;

  isFilterEnabled: Boolean = false;

  constructor(private ENUMS,
    private $api,
    private unwrapError,
    private clientLeadDetailsModal,
    private accountMemberLeadsDetailsService: AccountMemberLeadsDetailsService ) {
    'ngInject';
    this.loading = true;
  }
  $onInit = () => {
    this.isFilterEnabled = true;
    this.getAccountMembersLeads();
  }

  searchLeads = (searchParams) => {
    if (_.isEmpty(searchParams)) {
      this.isEventDateFilter = false;
      this.isOwnerFilter = false;
      this.isCityFilter = false;
      this.isStatusesFilter = false;
    }

    this.isFilterEnabled = false;
    const page = 0;
    if (!searchParams.owner) {
      this.search.primaryClients = [];
    } else if (searchParams.owner) {
      this.accountMemberLeadsDetailsService.getPrimaryClient({ searchParams, members: this.members });
    }
    return this.$api.Leads.getAccountLeads({ searchParams, page: page, leadsPerPage: this.tableViewLeadsPerPage, viewType: this.viewType  })
      .then((res) => {
        this.accountMembersLeads({ res, page });
        this.loading = false;
      })
      .catch((error) => {
        this.unwrapError(error);
    });
 }

  hasFilter = (filterName) => {
    if (filterName === 'currentStatuses') {
      return this.isStatusesFilter;
    }
    if (filterName === 'eventDate') {
      return this.isEventDateFilter;
    }
    if (filterName === 'city') {
      return this.isCityFilter;
    }
    if (filterName === 'owner') {
      return this.isOwnerFilter;
    }
  }

  clearFilter = (filterName) => {
    if (filterName === 'currentStatuses') {
      this.isStatusesFilter = false;
    }
    if (filterName === 'eventDate') {
      this.isEventDateFilter = false;
    }
    if (filterName === 'city') {
      this.isCityFilter = false;
    }
    if (filterName === 'owner') {
      this.isOwnerFilter = false;
    }
    unset(this.search, filterName);
    return this.searchLeads(this.search);
  }

  hasActiveFilters = () => {
    for (const key in this.search) {
      if (this.search.hasOwnProperty(key)) {
        const value = this.search[key];
        if (key === 'owner') {
          this.isOwnerFilter  = value.length !== 0 ? true : false;
        } else if (key === 'currentStatuses') {
          this.isStatusesFilter = value.length !== 0 ? true : false;
        } else if (key === 'city') {
          this.isCityFilter = value.length !== 0 ? true : false;
        } else if (key === 'eventDate') {
          this.isEventDateFilter = !_.isEmpty(value) ? true : false;
        }
      }
    }

    if (!this.isOwnerFilter && !this.isStatusesFilter && !this.isCityFilter && !this.isEventDateFilter) {
      return false;
    }

    return Object.keys(this.search).length;
  }

  clearAllFilters = () => {
    this.loading  = true;
    this.search = {};
    this.leads = [];
    this.activeLeads = [];
    this.confirmedLeads = [];
    this.isEventDateFilter = false;
    this.isOwnerFilter = false;
    this.isCityFilter = false;
    this.isStatusesFilter = false;
    return this.getAccountMembersLeads();
  }

  toggleFilterSidebar = () => {
    this.ui.displaySidebar = !this.ui.displaySidebar;
  }

  getAccountMembersLeads = (page = 0) => {
    return this.$api.Leads.getAccountLeads({ page: page, leadsPerPage: this.tableViewLeadsPerPage, viewType: this.viewType  })
      .then((res) => {
        this.accountMembersLeads({ res, page });
        this.loading = false;
      })
      .catch((error) => {
        this.unwrapError(error);
  });
 }

  accountMembersLeads = ({ res, page }) => {
    const leadsData = this.accountMemberLeadsDetailsService.setLeads(res);
    if (this.isFilterEnabled) {
      this.cities = leadsData.cities;
    }
    this.leads = leadsData.leads;
    this.activeLeads = leadsData.activeLeads;
    this.concludedLeads = leadsData.concludedLeads;
    this.inactiveLeads = leadsData.inactiveLeads;
    this.confirmedLeads = leadsData.confirmedLeads;
    this.numLeads = leadsData.numLeads;
    this.currentPage = page + 1;
  }

  displayEventName = (request) => {
    return (request.occasion && request.occasion !== 'Other') ? request.occasion : request.customOccasion;
  }

  openLeadDetailsModel = (lead) => {
    return this.clientLeadDetailsModal(lead);
  }

  openLeadPage = (lead) => {
    const url = `/recos/${lead._id.toString()}`;
    window.open(url);
  }

  displayLeadStatus = (leadId) => {
    let status;
    if (this.activeLeads.some(l => l._id === leadId)) {
      status = 'In Progress';
    } else if (this.concludedLeads.some(l => l._id === leadId)) {
      status = 'Completed';
    } else if (this.inactiveLeads.some(l => l._id === leadId)) {
      status = 'Closed';
    } else if (this.confirmedLeads.some(l => l._id === leadId)) {
      status = 'Booked';
    }
    return status;
  }
}


export const AccountMembersTableViewComponent = {
  controller: AccountMembersTableViewController,
  template: require('./account-members-table-view.component.jade'),
  bindings: {
    members: '='
  }
};
