// Services
import { ApiService } from 'spc/shared/api/api.service';
import { UserService } from 'spc/services/user.service';
import { ToastService } from 'spc/shared/toast.service';
import { CityTzMapperService } from '../../shared/cityTZmapper.service';

// Dependencies
import _ENUMS from 'common/dist/enums';
import debounce from 'lodash/debounce';
import time from 'common/dist/time';
import get from 'lodash/get';
import set from 'lodash/set';

// Types
import { DUser } from 'spc/lib/database/types/user';
import { LeadRequest } from 'lib/database/types/lead';
import  { DBaseUser } from 'lib/database/types/base-user';
import { RawCompany } from 'spc/lib/database/types/company';
import moment from 'moment-timezone';
import { isEmpty } from 'lodash';


interface PreSaveUser {
  profile: {
    name: {
      first: string,
      last: string,
    }
  };
  company?: RawCompany | string;
  internal?: {
    referrer?: {
      service: string;
    };
  };
  admin?: {
    heardAbout: string;
    heardAboutDetails: string;
  };
}

interface PreSaveLead {
    admin?: {
      source?
      createdByAdmin: boolean;
    };
    request?: LeadRequest;
}

interface Location {
  city: string;
  state: string;
  isTopLevelLocation: boolean;
  displayName?: string;
  neighborhoods: any[];
}

class AdminLeadFormController {
  searchName: string;
  lead: PreSaveLead = {
    admin: { createdByAdmin: true },
    request: {}
  };
  client: PreSaveUser;
  clients: DBaseUser[];
  sendEmail: boolean;
  TIMES: { value: number, display: string }[] = time.getTimeSelectOptions(15);
  ui: {
    submitting: boolean;
    displayForm: boolean;
    newClient: boolean;
  } = {
    submitting: false,
    displayForm: false,
    newClient: false,
  };
  heardAboutOptions: string[] = _ENUMS.concierge.adminHeardAbout.concat('Other');
  errors: { profile?: {}, request?: {}} = {};
  owner: DUser;
  conciergeTeam: DUser[];
  close: () => void;
  locations:  Location[];
  neighborhoodsMap: { [area: string]: { [city: string]: string[] } };

  constructor (private $api: ApiService, private ENUMS, private neighborhoodModal, private unwrapError, private toast: ToastService, private $user: UserService,  private cityTzMapperService: CityTzMapperService) {
    'ngInject';
    this.owner = this.$user.$;
  }

  $onInit = () => {
    return this.$api.Landing.getLocation()
      .then(data => this.neighborhoodsMap = data.neighborhoodsMap)
      .catch(error => this.unwrapError(error));
  }

  openNeighborhoodModal = () => {
    return this.neighborhoodModal({ selectedArea: this.lead.request.city, currentChoices: this.lead.request.neighborhoods, neighborhoodsMap: this.neighborhoodsMap })
      .then(response => this.lead.request.neighborhoods = response.value.currentChoices);
  }

  setDate = (date) => {
    this.lead.request.date = date;
  }

  createNewUser = () => {
    if (this.searchName) {
      const [firstName, lastName] = this.searchName.split(' ');
      this.client = {
        profile: {
          name: {
            first: firstName,
            last: lastName
          },
        },
        internal: {
          referrer: { service: 'direct' }
        },
      };
    this.ui.displayForm = true;
    this.ui.newClient = true;
  }
}

  setExistingUser = (user) => {
    this.client = user;
    this.ui.displayForm = true;
    this.ui.newClient = false;
  }

  debouncedUserSearch = debounce((name) => {
    if (name.length < 3) {
      return;
    }
    this.$api.Admin.Search.allBaseUsers(name)
      .then((response) => {
        this.clients = response.data.data;
      })
      .catch(error => this.unwrapError(error));
  }, 1000);

  checkForErrors = (baseValue) => {
    if (this.errors[baseValue]) {
      this.errors[baseValue] = false;
    }

    if (baseValue === 'request.budgetPerGuest' || baseValue === 'request.totalEventBudgetDollars') {
      this.errors['request.budgetPerGuest'] = false;
      this.errors['request.totalEventBudgetDollars'] = false;
    }
  }

  selectCompany = (company) => {
    this.client.company = company;
  }

  validateRequiredFields = () => {
    this.errors = {};
    return this.$api.Leads.validate({ lead: this.lead, client: this.client })
      .then((res) => {
        if (res.errors && !isEmpty(res.errors)) {
          const clientErrors = res.errors.client;
          const leadErrors = res.errors.lead;

          const clientErrorsData = clientErrors ? clientErrors : {};
          const leadErrorsData = leadErrors ? leadErrors : {};

          this.errors = { ...clientErrorsData, ...leadErrorsData };

          throw new Error('Validation failed'); // Throw an error to trigger the catch block
        }
      })
      .catch(error => this.unwrapError(error));
  }

  handleCustomCity = (customCity) => {
    const city = this.ENUMS.acceptableUserCities.find(_city => _city.value.toLowerCase() === customCity.toLowerCase() || _city.name.toLowerCase() === customCity.toLowerCase());
    return city ? city.value : customCity;
  }

  submitLead = () => {
    this.ui.submitting = true;
    const tzMappedDate = this.cityTzMapperService.getCityTZmappedDate({ city: this.lead.request.city, date: moment(this.lead.request.date).format('YYYY-MM-DD') });

    this.lead.request.date =  tzMappedDate ? tzMappedDate : this.lead.request.date;

    const originalDuration = +get(this.lead, 'request.duration');

    this.lead.request.duration = originalDuration * 60;

    if (get(this.client, 'admin.heardAbout') === 'Other') {
      this.client.admin.heardAbout = this.client.admin.heardAboutDetails;
      this.client.admin.heardAboutDetails = '';
    }

    const originalCity = get(this.lead, 'request.city');
    const customCity = get(this.lead, 'request.customCity');

    if (get(this.lead, 'request.city') === 'Other' && get(this.lead, 'request.customCity')) {
      this.lead.request.city = this.handleCustomCity(this.lead.request.customCity);
    } else if (get(this.lead, 'request.city') === 'Other' && !get(this.lead, 'request.customCity')) {
      this.errors['request.city'] = 'city error';
      this.ui.submitting = false;
      return;
    }

    set(this.lead, 'eventType', 'AdminCf');
    this.validateRequiredFields()
    .then(() => {
      // Validation successful, proceed with API call
      if (isEmpty(this.errors)) {
        return this.$api.Leads.create({
          client: this.client,
          lead: this.lead,
          sendEmail: this.sendEmail,
          adminId: this.owner.id
        });
      }
    })
    .then((response) => {
      this.ui.submitting = false;
      this.toast.goodNews('Lead Created!');
      this.close();
    })
    .catch((error) => {
      this.ui.submitting = false;
      this.lead.request.duration = +originalDuration;
      this.lead.request.city = originalCity ? originalCity.toString() : null;
      if (customCity) {
        this.lead.request.customCity = customCity.toString();
      }
      this.unwrapError(error);
    });
  }
}

export const AdminLeadFormComponent = {
  controller: AdminLeadFormController,
  template: require('./admin-lead-form.component.jade'),
  bindings: {
    conciergeTeam: '<',
    cities: '<',
    close: '&'
  }
};
