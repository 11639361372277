// NPM Dependencies
import * as angular from 'angular';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import set from 'lodash/set';

// External Dependencies
import LeadHelpers from 'common/dist/virtuals/Lead';
import { fullName, isActualUser } from 'common/dist/virtuals/User';

// Sixplus types
import { ApiService } from 'spc/shared/api/api.service';
import unset = require('lodash/unset');
import { RawLead } from 'database/types/lead';
import { RawAccount } from 'spc/lib/database/types/accounts';
import { getCityNameFromValue } from 'spc/utils/getCityDisplayName';

const NUM_LEADS_PER_PAGE = 50;

const ORDERING = {
  ID: 'readableId',
  SUBMITTED: 'createdAt',
  PRIORITY: 'admin.priorityValue',
  DUE: 'admin.dueDate',
  NEXT: 'admin.nextToDo',
  CITY: 'request.city',
  DATE: 'request.date',
  OWNER: 'owner',
  ASSISTANT: 'admin.assistant',
  'EVENT BUDGET': 'request.totalEventBudgetDollars',
  'BUDGET PER GUEST': 'request.budgetPerGuest',
  OCCASION: 'request.occasion',
  'MEAL TYPE': 'request.mealType',
  REFERRER: 'referrer.service',
  BUDGET: 'request.totalEventBudgetDollars'
};

const LEAD_DASHBOARD_DEBOUNCE_TIME = 1000;

class AdminLeadsDashboardController {
  referrers: string[] = this.ENUMS.referral.types;
  search: {
    city?: string,
    currentStatuses?: string[],
    text?: string,
    sort?: any
  } = {};
  ui: {
    displaySidebar: boolean
  } = { displaySidebar: false };
  currentPage: number;
  leadCount: number;
  leads: RawLead[];
  conciergeTeam: any[];
  ordering: any = ORDERING;
  link: string;
  getCityNameFromValue = getCityNameFromValue;

  constructor(
    private ENUMS,
    private $api: ApiService,
    private leadDetailsModal,
    private unwrapError,
    private toast,
    private $rootScope,
    private $timeout,
    private $location,
    private adminLeadFormModal,
    private now,
    private $window
  ) {
    'ngInject';
  }

  $onInit = () => {
    this.currentPage = 1;
    this.getCount()
      .then((data) => {
        this.leadCount = data.leads;
      })
      .then(() => this.displayLeads(this.currentPage - 1, this.search))
      .catch(error => this.unwrapError(error));
    this.$api.Admin.Leads.getAllOwners()
      .then(response => this.conciergeTeam = response.conciergeTeam)
      .catch(error => this.unwrapError(error));
  }

  isBaseUser = (user) => {
    return !isActualUser(user);
  }

  toggleFilterSidebar = () => {
    this.ui.displaySidebar = !this.ui.displaySidebar;
  }

  getFullstory = async(member) => {
        this.$api.Admin.Users.getlastSession({ user: member })
        .then(( res ) => {
            if (res) {
                const { FsUrl, CreatedTime } = res;
                this.link = FsUrl;
                this.$window.open(FsUrl);
            } else {
                this.toast.badNews('No Full-Story session found');
            }
        }).catch(error => this.unwrapError(error));
  }

  getCount = (search?) => {
    return this.$api.Admin.Leads.count(search)
      .then(response => response.data)
      .catch(error => this.unwrapError(error));
  }

  getSentRecosCount = (lead) => {
    return lead.recommendations.filter(r => r.isRecommended).length;
  }

  getActiveRecosCount = (lead) => {
    return lead.recommendations.filter(r => r.isRecommended && !r.archived).length;
  }

  getActiveThumbsUpRecosCount = (lead) => {
    return lead.recommendations.filter(r => r.isRecommended && r.isVisible && r.isInterested === 'Send Me A Proposal').length;
  }

  displayLeads = (pageNum, searchParams) => {
    pageNum = isFinite(pageNum) ? pageNum : 0;
    this.currentPage = pageNum + 1;
    return this.$api.Admin.Leads.get(pageNum, searchParams)
      .then((response) => {
        this.leads = response.data.data.leads.map(this.formatLead);
      })
      .catch(error => this.unwrapError(error));
  }

  formatLead = (lead) => {
    lead.activityIndicator = LeadHelpers.createActivityIndicator(lead);
    lead.numberOfProposalsSent = LeadHelpers.numberOfProposalsSent({ recommendations: lead.recommendations });
    lead.numberOfActiveProposalsSent = LeadHelpers.numberOfProposalsSent({ recommendations: lead.recommendations, active: true });
    if (lead.primaryClient) {
      lead.primaryClient.fullName = fullName(lead.primaryClient);
    }
    lead.admin.priorityLevel = isFinite(lead.admin.priorityValue) ? LeadHelpers.prioritize(lead) : '';
    return lead;
  }

  searchLeads = (pageNum, search) => {
    this.currentPage = 1;
    search = search ? search : this.search;
    return this.getCount(search)
      .then(data => this.leadCount = data.leads)
      .then(() => this.displayLeads(0, search))
      .catch(error => this.unwrapError(error));
  }

  debouncedSearchLeads = debounce(this.searchLeads, 500);

  changeReferrer = (lead, referrer) => {
    const originalReferrer = get(lead, 'referrer.service');
    const clone = angular.copy(lead);
    set(clone, 'referrer.service', referrer);
    return this.$api.Admin.Leads.update(clone)
      .then((response) => {
        set(lead, 'referrer.service', referrer);
        this.toast.goodNews('Referrer changed!', 'Referrer changed from ' + originalReferrer + ' to ' + referrer);
      })
      .catch((error) => {
        this.toast.badNews('Error!', 'Error updating referrer');
        this.unwrapError(error);
      });
  }

  displayOccasion = (request) => {
    return (request.occasion && request.occasion !== 'Other') ? request.occasion : request.customOccasion;
  }

  debouncedSaveNextToDo = debounce((nextToDo, lead) => {
    return this.saveAdmin({ 'admin.nextToDo': nextToDo }, lead)
      .then(() => this.toast.goodNews(`Saved Next To Do!`));
  }, LEAD_DASHBOARD_DEBOUNCE_TIME);

  saveCloseReason = (closeReason, lead) => {
    return this.saveAdmin({ 'admin.closeReason': closeReason }, lead)
      .then(() => this.toast.goodNews(`Saved Close Reason!`));
  }

  dueDateChange = (date, lead) => {
    return this.saveAdmin({ 'admin.dueDate': date }, lead)
      .then(() => this.toast.goodNews('Due date changed!'));
  }

  clearDueDate = (lead) => {
    return this.dueDateChange(null, lead);
  }

  isPastDue = (date) => {
    return this.now().isAfter(date, 'day');
  }

  isDue = (date) => {
    return this.now().isSame(date, 'day');
  }

  openNewLeadModal = () => {
    this.$api.Locations.get().then((data) => {
      return this.adminLeadFormModal(this.conciergeTeam, this.ENUMS.acceptableUserCities);
    });
  }

  savePriorityLevel = (priorityLevel, lead) => {
    return this.saveAdmin({ 'admin.priorityLevel': priorityLevel }, lead)
      .then(() => this.toast.goodNews('Saved Priority Level!'));
  }

  saveOwner = (owner, lead) => {
    if (owner._id && lead._id) {
      return this.$api.Admin.Leads.assignOwner(lead._id.toString(), owner._id.toString())
        .then(() => this.toast.goodNews('Saved Owner!'))
        .catch(() => {
          this.toast.badNews('Failed to Assign Owner');
        });
    }
  }

  saveAssistant = (assistant, lead) => {
    return this.saveAdmin({ 'admin.assistant': assistant._id }, lead)
      .then(() => {
        lead.admin.assistant = this.conciergeTeam.find(member => member._id.toString() === lead.admin.assistant);
        this.toast.goodNews('Saved Assistant!');
      });
  }

  saveAdmin = (update, lead) => {
    return this.$api.Admin.Leads.updateAdmin({ lead: lead._id, update })
      .then(response =>  lead.admin = response.admin)
      .catch((error) => {
        const message = get(error, 'data.error.message', 'Something went wrong trying to update the lead. Try refreshing the page and trying again.');
        this.toast.badNews('Something went wrong :(', message);
        this.unwrapError(error);
      });
  }

  setOrder = (header) => {
    const path = ORDERING[header];
    if (get(this.search.sort, path)) {
      this.search.sort[path] = this.search.sort[path] * -1;
    } else {
      this.search.sort = {};
      this.search.sort[path] = -1;
    }
    this.currentPage = 1;
    this.displayLeads(0, this.search);
  }

  // UI Funtions

  getClass = (lead) => {
    const classes = [];
    if (['Killed', 'Booked', 'Lost', 'Unqualified'].includes(lead.currentStatus)) {
      classes.push('bold');
    }
    if (['Killed', 'Lost'].includes(lead.currentStatus)) {
      classes.push('highlight-color');
    } else if (lead.currentStatus === 'Booked') {
      classes.push('alternate-color');
    }
    return classes.join(' ');
  }

  ownerInTeam = (owner) => {
    let ownerId;
    if (owner) {
     ownerId = owner._id ? owner._id.toString() : owner;
    }
    return !!this.conciergeTeam.find(member => member._id.toString() === ownerId);
  }

  isSelected = (member, owner) => {
    if (!owner) {
      return false;
    }
    const ownerId = owner._id ? owner._id : owner;
    return member._id.toString() === ownerId.toString();
  }

  getLocation = (lead) => {
    let domain = `${this.$location.protocol()}://${this.$location.host()}`;
    if (this.$location.port() !== 443) {
      domain = `${domain}:${this.$location.port()}`;
    }

    return domain;
  }

  // Filter Functions
  clearAllFilters = () => {
    this.search = {};
    return this.searchLeads(0, this.search);
  }

  clearFilter = (filterName) => {
    unset(this.search, filterName);
    return this.searchLeads(0, this.search);
  }

  clearClientText = () => {
    this.search.text = '';
    this.searchLeads(0, this.search);
  }

  hasActiveFilters = () => {
    return Object.keys(this.search).length;
  }

  hasFilter = (filterName) => {
    return this.search[filterName];
  }
}

export const AdminLeadsDashboardComponent = {
  template: require('./admin-leads-dashboard.component.jade'),
  controller: AdminLeadsDashboardController
};
