import LeadHelpers from 'common/dist/virtuals/Lead';
import price from 'common/dist/price';
import get from 'lodash/get';
import { RecosService } from 'spc/recos/recos.service';

class LeadListController {
  showFullList: any = {};
  concludedEvents: boolean;
  selectedTab: string;
  limitCount: boolean;

  constructor(private ENUMS, private recosService: RecosService) {
    'ngInject';
  }

  numberOfProposalsSent = LeadHelpers.numberOfProposalsSent;
  costBreakdown = price.fullPriceBreakdownDollars;

  displayGuests = (numGuests) => {
    return numGuests.min && numGuests.max ?
      `${numGuests.min} - ${numGuests.max}` :
        numGuests.min ? `${numGuests.min} min` : `${numGuests.max} max`;
  }

  recommendationState = (recommendation) => {
    if (recommendation.conversation) {
      return recommendation.conversation.requestState;
    } else {
      return 'Recommended';
    }
  }

  showToggleButton = (lead) => {
    const visibleRecommendations = lead.recommendations.filter(reco => !reco.archived && !this.isRecoBooked(reco));
    return visibleRecommendations.length;
  }

  toggleFullList = (lead) => {
    this.showFullList[lead._id] = this.showFullList[lead._id] || false;
    this.showFullList[lead._id] = !this.showFullList[lead._id];
  }

  isRecoBooked = (reco) => {
    return this.ENUMS.bookingRequestState.paidStates.includes(get(reco, 'conversation.requestState'));
  }

  recosLength = (recoArray) => {
    return recoArray.filter(reco => !reco.archived).length;
  }

  findBookedConversationUrl = (lead) => {
    const recommendations = lead.recommendations.filter(this.isRecoBooked);
    if (!recommendations.length) {
      return;
    }
    if (recommendations.length === 1) {
      const reco = recommendations[0];
      return { url: `/client/conversation/${reco.conversation._id}`, message: this.ENUMS.bookingRequestState.cancelledPostDepositStates.includes(reco.conversation.requestState) ? 'View Your Cancelled Booking' : 'View Your Booking' };
    } else {
      // If there are more than one paid recos on a lead (this can happen if there is a post deposit cancellation) we want to find the one that's not cancelled and display that url
      const bookedReco = recommendations.find(reco => this.ENUMS.bookingRequestState.bookedStates.includes(reco.conversation.requestState));
      return { url: `/client/conversation/${bookedReco.conversation._id}` , message: 'View Your Booking' };
    }

  }

  findBookedRecommendation = (lead) => {
    return lead.recommendations.find(this.isRecoBooked);
  }
}


export const LeadListComponent = {
  controller: LeadListController,
  template: require('./lead-list.component.jade'),
  bindings: {
    leads: '<',
    concludedEvents: '<',
    selectedTab: '<',
    limitCount: '<'
  }
};
