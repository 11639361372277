import { ApiService } from 'spc/shared/api/api.service';
import { UserService } from 'spc/services/user.service';

import { RawList } from 'spc/lib/database/types/list';
import{ RawBaseUser } from 'spc/lib/database/types/base-user';
import { ListMapService } from '../map/list-map.service';
import { RawListVenue } from 'spc/lib/database/types/list-venue';
import getListOwnerFullName from '../list-service';
import get from 'lodash/get';
import { RecoRequestService } from 'spc/recos/reco-request/reco-request.service';
import { RawVenue } from 'spc/lib/database/types/venue/venue';
import { RawDrink } from 'spc/lib/database/types/drink';
import { RawMenu } from 'spc/lib/database/types/menu';
import { ReviewsService } from 'spc/reviews/reviews.service';
import { sortParameters } from '../../../../database/constants/listConstants';

class IndividualListController {
  venueId: string;
  venue: RawVenue;
  drinks: RawDrink[];
  menus: RawMenu[];
  user: RawBaseUser;
  isPremiumUser: boolean = false;
  list: RawList;
  savedVenues: RawListVenue[];
  canEdit: boolean;
  spaces: any[];
  currentSortValue: string;
  sortParameters = sortParameters;

  ui: {
    map: boolean;
    spaces: boolean;
  };

  listId: string = this.$routeParams.id;
  createdBy: string;
  VenueSearchMapService: any;

  constructor(
    private $user: UserService,
    private $api: ApiService,
    private unwrapError,
    private $scope,
    private $routeParams,
    private $rootScope: ng.IRootScopeService,
    private $cloudinary,
    private listVenueSearchModal,
    private addVenueToListModal,
    private editListModal,
    private toast,
    private addListCollaboratorsModal,
    private removeListCollaboratorsModal,
    private listVenueViewModal,
    private listMapService: ListMapService,
    private listOrderModal,
    private $clickOk,
    private recoRequestService: RecoRequestService,
    private recoRequestModal,
    private requestConfirmationModal,
    private reviewsService: ReviewsService,
    private eventWithLiteModal,
    private createDuplicateListModal,
    private listShareModal,
    private $location,
  ) {
    'ngInject';
    this.ui = {
      map: true,
      spaces: false
    };
  }

  $onInit() {
    this.$scope.$broadcast('START_REQUEST_LISTENER');
    this.getList(this.listId);
    this.$rootScope.$emit('$viewReady', 'HIDE_FOOTER');
  }

  setSorting(param) {
    this.currentSortValue = param.path;
  }

  getList(id) {
    return this.$api.Lists.getSingleList(id)
      .then((res) => {
        this.list = res.list;
        this.savedVenues = this.list.saved.filter(s => s.venue);
        this.ui.map = !!this.savedVenues.length;
        this.setUser();
        this.getCreatedBy(this.list);
        this.drawMakers();
      })
      .catch(error => this.unwrapError(error));
  }

  getDomain = (lead) => {
    let domain = `${this.$location.protocol()}://${this.$location.host()}`;
    if (this.$location.port() !== 443) {
      domain = `${domain}:${this.$location.port()}`;
    }
    return domain;
  }

  openListShareModal = () => {
    return this.listShareModal();
  }

  getTime = (data) => {
    const time = new Date(data);
    return time;
  }

  openRecoRequestModal = async(data) => {
    if (data.status !== 'Lite') {
      await this.fetchVenue (data._id);
    } else {
      const params = { origin: 'venue-view-venue', venue: data };
      this.recoRequestModal(params)
        .then((data) => {
          const { request, status, lead } = get(data, 'value', {});
          return this.requestConfirmationModal(request, status, lead);
        });
    }
  }
  addToEvent = (venue) => {
    this.eventWithLiteModal({ status: venue.status, venue: venue, user: this.user })
      .then( (res) => {
        if (res.value.proceed) {
            this.openRecoRequestModal(venue);
        }
      });
    }

  fetchVenue (venueId) {
    return this.$api.Venues.get(venueId, {
      menus: true,
      drinks: true,
      getNonVisible: false,
      profileOwner: false,
      listView: true,
      _id: venueId
    })
    .then((res) => {
      const { venue, drinks, menus } = res.data.data;
      const params = { origin: 'venue-view-venue', venue, menus, drinks };
      this.recoRequestModal(params)
        .then((data) => {
          const request = get(data, 'value.request', {});
          return this.requestConfirmationModal(request);
      });
    })
    .catch(error => this.unwrapError(error));
  }

  archiveList (list) {
    list.isVisible = false;
    return this.$api.Lists.toggleListVisibility(list)
      .then(() => {
        window.location.replace(this.getListDashboardLink());
      })
      .catch(error => this.unwrapError(error));
  }

  getListDashboardLink = () => {
    let domain = `${this.$location.protocol()}://${this.$location.host()}`;
    if (this.$location.port() !== 443) {
      domain = `${domain}:${this.$location.port()}`;
    }
    return `${domain}/user/lists`;
  }

  deleteList (listId)  {
    const showCancel = true;
    return this.$clickOk('Are you sure you want to delete this list?', showCancel)
      .then((response) => {
        if (get(response, 'value.cancel')) {
          return;
        }
        return this.$api.Lists.delete(listId)
          .then(() => {
            window.location.replace(this.getListDashboardLink());
          });
      })
      .catch(error => this.unwrapError(error));
  }

  filterSpaces (spaces) {
    return spaces.filter(space => space.isVisible);
  }

  filterAddOns (addOns) {
    return addOns.filter(a => !a.isDeleted);
  }

  getCreatedBy(list) {
    this.createdBy = getListOwnerFullName(list);
  }

  setUser() {
    this.$api.Auth.me()
      .then((res) => {
        this.user = res.data.user;

        if (this.user && this.user.accountTier) {
          this.isPremiumUser = this.user.accountTier.includes('Pro') || this.user.accountTier.includes('Premium') ? true : false;
        }

        if (this.list) {
          const listUser = this.user ? this.list.users.find(user => user.client._id === this.user._id) : undefined;
          this.canEdit = listUser ? true : false;
        }
    }).catch(error => this.unwrapError(error));
  }

  isPrimaryUser() {
    if (get(this.list, 'users') && this.user) {
      const primaryUser = this.list.users.find(u => u.isPrimary);
      return primaryUser.client._id === this.user._id ? true : false;
    }
    return false;
  }

  showNotes(venue) {
    const user = this.user;
    if (!user) {
      return false;
    }

    const venueStatus = venue.status;

    if (venueStatus !== 'Published' && venueStatus !== 'Lite' && venueStatus !== 'Private' && !this.isPremiumUser) {
      return false;
    }

    return true;
  }

  organizeVenues = () => {
    const venues = this.savedVenues;
    return this.listOrderModal(this.list, venues)
      .then(() => this.getList(this.listId))
      .catch(err => this.unwrapError(err));
  }

  getVisibleSpaceCount(spaces) {
    return spaces.filter(space => space.isVisible === true).length;
  }

  openVenueSearchModal() {
    return this.listVenueSearchModal(this.list)
      .then((res) => {
        if (res.list) {
          this.list.saved = res.list.saved;
          this.savedVenues = this.list.saved.filter(s => s.venue);
          this.getList(this.listId);
          this.drawMakers();
          this.getList(this.listId);
        }
      }).catch(error => this.unwrapError(error));
  }

  openCreateDuplicateListModal() {
    return this.createDuplicateListModal(this.list, )
      .then((res) => {
        if (res.value !== '$document' && res.value !== '$closeButton') {
          window.location.replace(this.getListDashboardLink());
        }
      }).catch(error => this.unwrapError(error));
  }

  openNoteModal(venueId) {
    return this.listVenueViewModal(venueId, 'NOTES', this.listId)
      .then(() => {
        this.getList(this.listId);
      });
  }

  openAddVenueToListModal(venue) {
    return this.addVenueToListModal(venue);
  }

  openEditListModal() {
    return this.editListModal(this.list)
      .then((res) => {
        if (res.value && res.value.updatedList) {
          this.list = res.value.updatedList;
          this.toast.goodNews('List saved!');
        }
      })
      .catch(error => this.unwrapError(error));
  }

  openCollaboratorsModal() {
    return this.addListCollaboratorsModal({ user: this.user, list: this.list })
      .then(() => {
        this.getList(this.listId);
      });
  }

  openRemoveCollaboratorsModal() {
    return this.removeListCollaboratorsModal({ user: this.user, list: this.list })
      .then(() => {
        this.getList(this.listId);
      });
  }

  openVenueViewModal(venue) {
    const id = venue._id.toString();
    return this.listVenueViewModal(id, 'VENUE', this.listId);
  }

  drawMakers() {
    const venues = this.savedVenues.map(s => s.venue);
    this.listMapService.drawVenueMarkers({ venues, scope: this.$scope });
  }

  handleHover = (venue) => {
    this.listMapService.triggerMarker(venue._id);
  }

  handleHoverOut = (venue) => {
    this.listMapService.resetIcon(venue._id);
  }

  selectMarker(venueId) {
    this.listMapService.selectMarker(venueId);
  }

  isSelected(venue) {
    return this.listMapService.selectedVenueId === venue._id.toString();
  }

  isLoggedIn() {
    return this.user;
  }

  deleteVenue(venueId) {
    const showCancel = true;
    return this.$clickOk('Are you sure you want to remove this venue from the list?', showCancel)
      .then((response) => {
        if (get(response, 'value.cancel')) {
          return;
        }
        return this.$api.Lists.deleteListVenue(venueId, this.listId)
          .then(() => {
            this.getList(this.listId);
          });
      })
      .catch(error => this.unwrapError(error));
  }

  addEscapeChar() {
    return '<';
  }

}

export const IndividualListComponent = {
  controller: IndividualListController,
  template: require('./individual-list.component.jade'),
};
