import { RecommendationService } from 'spc/shared/recommendation.service';
import { Recommendation } from 'server/api/admin/leads/models';

class RecoOrderController {
  recos: Recommendation[];
  leadId: string;
  status: string;
  close: (revised: Recommendation[]) => void;

  constructor(private $scope, private $cloudinary, private recommendationService: RecommendationService) {
    'ngInject';
    this.$scope.$cloudinary = this.$cloudinary;
  }

  $onInit = () => {
    this.$scope.recos = this.recos;
    this.$scope.getRecoStatus = this.getRecoStatus;
  }

  getRecoStatus = (reco) => {
    if (reco.venue.status === 'Lite' && reco.isInterested) {
      if (reco.isInterested === 'Liked Lite' && reco.availabilityDeltas.length === 1) {
        return 'Check Availability';
      }
      else if (reco.isInterested === 'Liked Lite' && reco.availabilityDeltas.length > 1) {
        return reco.availabilityDeltas[reco.availabilityDeltas.length - 1].value;
      }
      return 'Not A Good Fit';
    }
    return reco.isInterested;
  }

  saveOrder = () => {
    const recosToUpdate: Recommendation[] = this.$scope.recos.map((reco, index) => {
      const recoInfo = {
        _id: reco._id,
        order: index,
      };
      return recoInfo;
    });
    return this.recommendationService.updateOrder({ recosToUpdate, leadId: this.leadId })
      .then(response => this.close(response.recommendations));
  }
}

export const RecoOrderComponent = {
  controller: RecoOrderController,
  controllerAs: '$ctrl',
  template: require('./reco-order-component.jade'),
  bindings: {
    recos: '<',
    leadId: '<',
    close: '<'
  }
};
