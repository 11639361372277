import { get } from 'lodash';

// External Dependencies

export default ['ngDialog', function (ngDialog) {
  return function (params: { status: string, venue, user}) {

    return ngDialog.open({
      className: 'ngdialog-theme-small',
      controller: ['$scope', function ($scope) {
        if (params.status === 'Lite' ) {
          $scope.case = 'CASE_4';
        }
        $scope.user = params.user;
        $scope.venue = params.venue;
        // "&" is reserved  in 'mailTo' so we replace it with %26
        const modVenueName = get(params, 'venue.data.name', '').replace('&', '%26');
        $scope.venueName = modVenueName;
        $scope.close = (proceed: boolean) => {
          $scope.closeThisDialog({ proceed: proceed });
        };
      }],
      template: require('./reco-request-cancel.jade'),
      plain: true
    }).closePromise;
  };
}];
