const recoFeedbackModal = ['ngDialog', function(ngDialog) {
  return function (recommendation) {
    return ngDialog.open({
      template: '<reco-feedback recommendation="$ctrl.recommendation" close="$ctrl.close(recommendation)"></reco-feedback>',
      controllerAs: '$ctrl',
      className: 'ngdialog-theme-small info-modal',
      plain: true,
      overlay: true,
      controller: ['$scope', function ($scope) {
        this.recommendation = recommendation;
        this.close = (reco) => {
          $scope.closeThisDialog({ recommendation: reco });
        };
      }]
    }).closePromise;
  };
}];

export default recoFeedbackModal;
