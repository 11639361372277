import { get, includes, isArray } from 'lodash';
/**
 * Simple modal that requires no user input
 * besides clicking "OK" and allows us to
 * customize the message displayed
*/
module.exports = ['ngDialog', '$q', function(ngDialog, $q) {
  return function(message, showCancel, btnText?) {
    return ngDialog.open({
      template: require('./click-ok.jade'),
      className: 'ngdialog-theme-small info-modal',
      overlay: true,
      plain: true,
      closeByEscape: false,
      controller: ['$scope', function($scope) {
        $scope.message = message;
        $scope.showErrors = isArray(message);
        $scope.cancel = cancel;
        $scope.showCancel = showCancel;
        $scope.btnText = btnText ? btnText : 'OK';
        $scope.closeIfEscape = closeIfEscape;

        /**
         * Closes the dialog if escape key is pressed with a custom return value
         *
         * @param {Event} event
         */
        function closeIfEscape(event) {
          if (event.keyCode === 27) {
            $scope.cancel();
          }
        }

        function cancel() {
          $scope.closeThisDialog({ cancel: true });
        }
      }]
    }).closePromise.then(function(data) {
      const isCancel = includes(['$document', '$closeButton'], get(data, 'value'));
      if (isCancel) {
        data.value = { cancel: true };
      }

      return data;
    });
  };
}];
