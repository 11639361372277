import { RawVenue } from 'spc/lib/database/types/venue/venue';
import { ReviewsService } from '../reviews/reviews.service';
import { DLead } from 'lib/database/types/lead';
import { Accolade } from './venue-view.component';

class VenueLiteController {
    similarVenues: RawVenue[];
    getAccolades: (accolade: Accolade) => string;
    requestModal: ({ origin, venue }) => void;
    showReview: ({ review }) => boolean;
    venuePhotosCopy: any;
    venue: any;
    venuePrice;
    venuePriceDim;
    leads: DLead;
    constructor(
        private $cloudinary,
        private photoDialog,
        private unwrapError,
        private $api,
        private $user,
        private eventWithLiteModal,
        private $scope,
        private addVenueToListModal,
        private reviewsService: ReviewsService,
        private googleStreetViewModal
        ) {
        'ngInject';
    }


    $onInit = () => {
        this.venuePhotosCopy = [...this.venue.data.photos];
        this.getActiveLeads();
        if (this.venue.data.photos.length) {
            const coverImgIndex = this.venue.data.photos.findIndex(p => this.venue.coverImage.url === p.url);
            if (coverImgIndex >= 0) {
                this.venue.data.photos.splice(coverImgIndex, 1);
            }
        }
    }

    private addLiteVenueToList = () => {
        return this.addVenueToListModal(this.venue);
    }

    private addToEvent = (params) => {
        this.eventWithLiteModal({ status: this.venue.status, venue: this.venue, user: this.$user.$ })
            .then( (res) => {
                if (res.value.proceed) {
                    this.requestModal(params);
                }
            });
    }

    public openPhotoDialog = ({ photos, initialPhoto }): Promise<any> => {
        return this.photoDialog({ photos, initialPhoto });
    }

    getActiveLeads = () => {
        if (this.$user.isLoggedIn()) {
            return this.$api.Leads.getUserLeads({ activeOnly: true })
                .then((response) => {
                    this.leads = response.leads;
                })
                .catch(error => this.unwrapError(error));
        }
    }

    openGoogleStreetViewModal = (venue) => {
        return this.googleStreetViewModal(venue);
      }
}

export const VenueLiteViewComponent = {
    controller: VenueLiteController,
    template: require('./venue-lite-view.component.jade'),
    bindings: {
        venue: '<',
        similarVenues: '<',
        venuePrice: '<',
        venuePriceDim: '<',
        requestModal: '&',
        showReview: '&'
    },
};
