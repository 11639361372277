import debounce from 'lodash/debounce';
import { DVenue } from 'spc/lib/database/types/venue/venue';
import { ApiService } from 'spc/shared/api/api.service';
import { UserService } from 'spc/services/user.service';

class AlternativeVenueController {
  input: string;
  venues: DVenue[];
  ui: { loading: boolean, hasExactMatch: boolean } = { loading: false, hasExactMatch: false };
  save: (update: { [key: string]: string; }) => any;

  constructor(private $api: ApiService, private unwrapError, private $scope, private $user: UserService) {
    'ngInject';
  }

  debouncedVenueSearch = debounce((name) => {
    this.ui.loading = true;
    return this.$api.Admin.Venues.findManyByName(`"${name}"`, { skipVisibility: true })
      .then((response: { data: { venues: DVenue[] }}) => {
        this.ui.loading = false;
        this.venues = response.data.venues;
        this.ui.hasExactMatch = !!this.venues.find(venue => venue.data.name.toLowerCase() === name.toLowerCase());
      })
      .catch(error => this.unwrapError(error));
    }, 300);

  createAndSaveAlternativeVenue = (name) => {
    return this.$api.Venues.new({ data: { name } })
      .then(() => this.saveAlternativeVenue(name))
      .catch(error => this.unwrapError(error));
  }

  saveAlternativeVenue = (name) => {
    this.$scope.closeThisDialog({ name });
  }
}

export default ['ngDialog', function(ngDialog) {
  return function() {
    return ngDialog.open({
      template: require('./alternative-venue.modal.jade'),
      className: 'ngdialog-theme-small',
      overlay: true,
      plain: true,
      controllerAs: '$ctrl',
      controller: AlternativeVenueController
    }).closePromise;
  };
}];
