import { every, get, set, remove, cloneDeep } from 'lodash';
import { convertURL } from '../../services/convertUrl';

export default ['ngDialog', function (ngDialog) {
  return function (request) {
    return ngDialog.open({
      template: require('./attachments.jade'),
      className: 'ngdialog-theme-plain',
      overlay: false,
      plain: true,
      controller: ['$scope', '$cloudinary', '$api', 'linkAttachmentModal', 'unwrapError', '$timeout', function ($scope, $cloudinary, $api, linkAttachmentModal, unwrapError, $timeout) {
        $scope.addLinkAttachment = false;
        $scope.attachmentsUpdated = false;
        $scope.cloudinaryAttachments = [];
        $scope.linkAttachments = [];

        $scope.finish = finish;
        $scope.savePhotos = savePhotos;
        $scope.openLinkAttachmentModal = openLinkAttachmentModal;
        $scope.deleteLink = deleteLink;
        $scope.convertUrl = convertURL;

        $scope.setAttachments = setAttachments;

        init();
        ///// Initialization function

        function init() {
          $scope.request = request;
          $scope.attachments = get($scope, 'request.data.payment.attachments', []);
          $scope.initialAttachments = cloneDeep(get($scope, 'request.data.payment.attachments'));
          setAttachments($scope.attachments);
          $scope.buttonText = `I'm done`;
          $scope.canSubmit = determineCanSubmit();
        }

        ///// Listeners
        $scope.$on('PHOTO_UPLOADING', function () {
          $scope.buttonText = 'Uploading...';
        });

        $scope.$on('PHOTO_UPLOADED', function () {
          $scope.buttonText = `I'm finished`;
        });

        $scope.$on('PHOTO_REMOVED', function (ev, params) {
          ev.stopPropagation();
          const url = params.url;
          $scope.request.data.payment.attachments =
            remove($scope.request.data.payment.attachments,
              attachment => attachment.url === url);
        });

        ///// Public functions
        /**
         * Overwrites request's array of attachments with updated array
         *
         * @param {Array} attachments
         * @mutates `$scope.request`
         */
        function savePhotos(attachments) {
          if ($scope.cloudinaryAttachments.length > attachments.length) {
            const removed = $scope.cloudinaryAttachments.filter(a => attachments.every(at => at.url !== a.url));
            removeAttachment(removed);
          }
          else if ($scope.cloudinaryAttachments.length <= attachments.length) {
            set($scope, 'cloudinaryAttachments', attachments);
            updateAttachments($scope.cloudinaryAttachments);
            $timeout(() => $scope.canSubmit = determineCanSubmit());
          }
        }

        function updateAttachments(attachments) {
          attachments.forEach((attachment) => {
            if ($scope.request.data.payment.attachments.every(a => a.url !== attachment.url)) {
              $scope.request.data.payment.attachments.push(attachment);
              isAttachmentsChanged();
            }

            if (!!$scope.request.data.payment.attachments.find(a => a.url === attachment.url && a.caption !== attachment.caption)) {
              $scope.request.data.payment.attachments.forEach((a) => {
                if (a.url === attachment.url && a.caption !== attachment.caption) {
                  a.caption = attachment.caption;
                }
              });
            }
          });
        }

        function isAttachmentsChanged() {
          const attachments = get($scope, 'request.data.payment.attachments');
          if ($scope.initialAttachments.length !== attachments.length) {
            $scope.attachmentsUpdated = true;
            return;
          }

          attachments.forEach((attachment) => {
            if ($scope.initialAttachments.every(a => a.url !== attachment.url)) {
              $scope.attachmentsUpdated = true;
            } else {
              $scope.attachmentsUpdated = false;
            }
          });
        }

        function setAttachments(attachments) {
          attachments.forEach((attachment) => {
            const isNotAlreadyAdded = $scope.linkAttachments.every(a => a.url !== attachment.url);
            if (isNotAlreadyAdded && attachment.type === 'Direct') {
              $scope.linkAttachments.push(attachment);
            } else if (attachment.type !== 'Direct') {
              $scope.cloudinaryAttachments.push(attachment);
            }
          });
        }

        function removeAttachment(removed) {
          $scope.request.data.payment.attachments =
            remove($scope.request.data.payment.attachments,
              attachment => attachment.url !== removed[0].url);
          isAttachmentsChanged();
        }

        /**
         * Finishes and closes ngDialog
         *
         * @return {Promise}
         */
        function finish() {
          if (!$scope.attachmentsUpdated) {
            $scope.closeThisDialog();
          } else {
            return $api.Requests
              .saveReceipts({ request: $scope.request, pathsAndValues: {
                setPath: `data.payment.attachments`,
                value: $scope.request.data.payment.attachments
              } })
              .then(response => $scope.closeThisDialog({ request: get(response, `data.data`) }))
              .catch(error => unwrapError(error));
          }
        }

        function deleteLink({ index }: { index: number }) {
          const removed = $scope.linkAttachments.splice(index, 1);
          removeAttachment(removed);
        }


        /**
         * Determines if user can click submit button
         *
         * @return {Boolean}
         */
        function determineCanSubmit() {
          if (!get($scope, 'request.data.payment.attachments.length')) {
            return true;
          }
          const isAllValid = every($scope.request.data.payment.attachments, function (attachment: any) {
            return attachment.caption && attachment.url;
          });
          return isAllValid;
        }

        function openLinkAttachmentModal() {
          $scope.addLinkAttachment = true;
          linkAttachmentModal($scope.request).
            then(function(data) {
              if (get(data, 'value.request')) {
                const attachments = get(data, 'value.request');
                setAttachments(attachments);
                isAttachmentsChanged();
              }
              $scope.addLinkAttachment = false;
            }).
            catch(unwrapError);
        }
      }]
    }).closePromise;
  };
}];
