import { get, unset, debounce, isEmpty, set } from 'lodash';
import { RawBaseUser } from 'spc/lib/database/types/base-user';
import { UserService } from 'spc/services/user.service';
import { RawUser } from 'spc/lib/database/types/user';
import { ApiService } from 'spc/shared/api/api.service';
import { RawCompany } from 'spc/lib/database/types/company';
import { LeadRequest } from 'spc/lib/database/types/lead';

class ConciergeUserDetailsController {
  client: RawBaseUser | RawUser;
  companies: RawCompany[];
  userDetailsForm: any;
  user: RawUser;
  CLIENT_DETAILS_PATHS: any;
  modalOpen: boolean = false;
  errors;
  setClientDetails: (client: RawBaseUser | RawUser) => void;
  lead: LeadRequest;

  constructor(
    private $user: UserService,
    private $api: ApiService,
    private unwrapError,
    private ENUMS,
    private selectionModal) {
    'ngInject';
  }

  $onChanges = (data) => {
    this.$user.$waitFor('LOGGED_IN', () => {
      this.user = this.setLoggedInUserDetails(this.$user.$);
      this.client = this.setLoggedInUserDetails(this.$user.$);
    });
  }

  $onInit = () => {
    if (this.$user.$) {
      this.user = this.setLoggedInUserDetails(this.$user.$);
      this.client = this.setLoggedInUserDetails(this.$user.$);
    }
  }

  setLoggedInUserDetails = (userDetails) => {
    return JSON.parse(JSON.stringify(userDetails));
  }

  clearClientErrors = (path) => {
    if (get(this.errors, path)) {
      unset(this.errors, path);
    }
  }

  selectCompany = (company) => {
    this.client.company = company;
  }

  validateCompany = () => {
    return this.$api.Companies.validate({ companyName: this.client.company.name })
      .then((response) => {
        const errors = get(response, 'errors.company');
        if (errors) {
          this.errors = { ...this.errors, ...errors };
        }
      });
  }

  debounceValidateCompany = debounce(this.validateCompany, 300);

  togglecontactPreferance = (preferanceKey) => {
    if (!get(this, 'client.profile.contactPreferance')) {
      set(this, 'client.profile.contactPreferance', {});
    }
    set(this.client, `profile.contactPreferance[${preferanceKey}]`, !get(this.client, `profile.contactPreferance[${preferanceKey}]`));
    this.updateClientData();
  }

  updateClientData = (errorPath?) => {
    this.setClientDetails({ client: this.client });
    if (errorPath) {
      this.clearClientErrors(errorPath);
    }
  }

  setDefaultContactPreferance = () => {
    if (!get(this.client, 'profile.phone')) {
      set(this.client, 'profile.contactPreferance.textMessage', false);
      set(this.client, 'profile.contactPreferance.phoneCall', false);
      return;
    }
    set(this.client, 'profile.contactPreferance.textMessage', true);
    set(this.client, 'profile.contactPreferance.phoneCall', true);
  }

  updateClientPhone = () => {
    if (!(this.user) || get(this.user, 'profile.phone') !== get(this.client, 'profile.phone')) {
      this.setDefaultContactPreferance();
    } else {
      set(this.client.profile, 'contactPreferance', get(this.user, 'profile.contactPreferance'));
    }

    this.updateClientData();
  }

  openCompanySelectionModal = () => {
    return this.selectionModal({
      choices: this.companies.map(company => company.name),
      currentChoice: get(this.client, 'company.name'),
      options: { custom: true, search: true, name: 'company' }
    })
    .then((response) => {
      const choice = get<string>(response, 'value.choice');
      if (choice) {
        const selectedCompany = this.companies.find(company => company.name === choice);
        if (!selectedCompany) {
          set(this.client, 'company.name', choice);
          this.createAndSelectCompany(choice);
        } else {
          this.client.company = selectedCompany;
        }
      }
      this.updateClientData();
    })
    .catch(error => this.unwrapError(error));
  }

  openSourceSelectModal = () => {
    const heardAboutPath = 'admin.heardAbout';
    return this.selectionModal({
      choices: this.ENUMS.concierge.heardAbout,
      currentChoice: get(this.client, heardAboutPath),
      options: { custom: true, search: true, name: 'source' }
    }).then((response) => {
      const choice = get(response, 'value.choice');
      if (choice) {
        set(this, `client.${heardAboutPath}`, choice);
        this.updateClientData();
       }
    }).catch(error => this.unwrapError(error));
  }

  handleKeyDownOnModalField = ($event, modalFunc) => {
    if (this.modalOpen) {
      return;
    }
    if ($event.keyCode === 13 || $event.keyCode === 39 || $event.keyCode === 40) {
      this.modalOpen = true;
      return modalFunc()
        .then(() => this.modalOpen = false);
    }
  }

  getDomain = () => {
    if (!get(this.client, 'profile.email')) {
      return;
    }
    const email = get<string>(this.client, 'profile.email');
    return email.split('@')[1];
  }

  createAndSelectCompany = (name) => {
    this.validateCompany()
      .then(() => {
        if (!name) {
          return;
        }
        return this.$api.Companies.createCompany({ name, domains: [this.getDomain()] })
          .then((data) => {
            this.client.company = data.company;
            this.updateClientData();
          })
          .catch(error => this.unwrapError(error));
      });
  }

  getCompanies = () => {
    const email = get<string>(this.client, 'profile.email');

    if (!email) {
      return;
    }

    const domain = email.split('@')[1];

    if (!domain) {
      return;
    }

    return this.$api.Companies.getByDomain(domain)
      .then((data) => {
        this.companies = data.companies;
      })
      .catch(error => this.unwrapError(error));
  }

  debouncedGetCompanies = debounce(this.getCompanies, 300);

  handleEmailChange = () => {
    this.clearClientErrors('profile.email');
    this.updateClientData();
    return this.debouncedGetCompanies();
  }
}


export const conciergeUserDetailsComponent = {
  template: require('./concierge-user-details.component.jade'),
  controller: ConciergeUserDetailsController,
  bindings: {
    setClientDetails: '&',
    errors: '<',
    lead: '='
  }
};

