import { get } from 'lodash';
import { RawDrink } from 'spc/lib/database/types/drink';
import { RawMenu } from 'spc/lib/database/types/menu';
import { RawUser } from 'spc/lib/database/types/user';
import { RawVenue } from 'spc/lib/database/types/venue/venue';
import { ReviewsService } from 'spc/reviews/reviews.service';
import { UserService } from 'spc/services/user.service';
import { ApiService } from 'spc/shared/api/api.service';

class CSPQuickViewController {
  close: () => void;
  loading: boolean = false;
  slug: string;
  isPremiumUser: boolean = false;
  user: RawUser | null = null;

  venueId: string;
  listId: string;
  venue: RawVenue;
  drinks: RawDrink[];
  menus: RawMenu[];
  reviews: any;

  CRITERIA = ['venueRecommendation'];
  starsMap: {
    [key: string]: {
      fullStars: number;
      halfStars: number;
      emptyStars: number;
    }
  } = {};
  ui: 'VENUE' | 'HISTORY' | 'NOTES';

  constructor(
    private $cloudinary,
    private photoDialog,
    private unwrapError,
    private menuDetailModal,
    private spaceDetailModal,
    private recoRequestModal,
    private $api: ApiService,
    private eventWithLiteModal,
    private $user: UserService,
    private requestConfirmationModal,
    private reviewsService: ReviewsService
  ) {
    'ngInject';
    this.ui = 'VENUE';
  }

  $onInit = () => {
    if (this.slug) {
      this.getVenueData(this.slug);
    }
  }

  public getStars = () => {
    if (get(this.venue, 'data.ratingAndReviews')) {
      this.venue.data.ratingAndReviews.forEach((review: any) => {
        review.stars = this.reviewsService.calculateStars(review.rating);
      });
    }
  }

  public getVenueData = (slug) => {
    if (slug) {
      this.loading = true;
      return this.$api.Venues
        .get(slug, { menus: true, drinks: true, reviews: true })
        .then((res) => {
          const { venue, drinks, menus, reviews } = res.data.data;
          this.venue = venue;
          this.venue.data.spaces = this.filterSpaces(this.venue.data.spaces);
          this.drinks = drinks;
          this.menus = menus;
          this.loading = false;
          this.reviews = reviews;
          this.displaySixPlusStars();
          this.getStars();
          this.setUser();
        })
        .catch((error) => {
        this.loading = false;
        this.unwrapError(error);
      });
    }
  }

  filterSpaces (spaces) {
    return spaces.filter(space => space.isVisible);
  }

  public setUser = () => {
    this.user = this.$user.$ || null;
    if (this.user && this.user.accountTier) {
      this.isPremiumUser = this.user.accountTier.includes('Pro') || this.user.accountTier.includes('Premium');
    }
  }

  public showNotes = (): boolean => {
    const user = this.user;
    if (!user) {
      return false;
    }

    const venueStatus = this.venue.status;
    if (!this.isPremiumUser && !['Published', 'Lite', 'Private'].includes(venueStatus)) {
      return false;
    }

    return true;
  }

  public openRecoRequestModal = async (params: any) => {
    try {
      const data = await this.recoRequestModal(params);
      const { request, status, lead } = get(data, 'value', {});
      const res = await this.requestConfirmationModal(request, status, lead);

      if (!res.value) {
        this.closeQuickViewModel();
      }

    } catch (error) {
      this.unwrapError(error);
    }
  }

  public addToEvent = async (params: any) => {
    if (!this.isPremiumUser) {
      return;
    }
    try {
      const res = await this.eventWithLiteModal({ status: params.venue.status, venue: params.venue, user: this.user });
      if (res.value.proceed) {
        await this.openRecoRequestModal(params);
      }
    } catch (error) {
      this.unwrapError(error);
    }
  }

  public displaySixPlusStars = () => {
    const reviewData = this.reviewsService.calculateRatings(this.reviews);
    if (this.reviews && this.reviews.length) {
      this.CRITERIA.forEach((criteria) => {
        this.starsMap[criteria] = this.reviewsService.calculateStars(reviewData[criteria]);
      });
    }
  }

  public showReview = ({ review }: { review: any }) => {
    if (!review) {
      return false;
    }
    if (review.name === 'Google') {
      return !this.venue.hideReview.google;
    } else if (review.name === 'Yelp') {
      return !this.venue.hideReview.yelp;
    }
    return true;
  }

  public openPhotoDialog = ({ photos, initialPhoto }: { photos: any[], initialPhoto: any }): Promise<any> => {
    return this.photoDialog({ photos, initialPhoto });
  }

  public openVenueProfilePage = () => {
    const slug = this.venue.slug;
    const url = `/venue/${slug}`;
    window.open(url, '_blank');
  }

  public closeQuickViewModel = () => {
    this.close();
  }
}

export const cspQuickViewComponent = {
  controller: CSPQuickViewController,
  template: require('./csp-quick-view.component.jade'),
  bindings: {
    slug: '<',
    close: '<',
  }
};

