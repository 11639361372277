import { RawList } from 'spc/lib/database/types/list';

// constant
import { ANALYTICS_EVENTS } from '../../constants/ENUMS/analyticsEvents';

import UserHelpers from 'common/dist/virtuals/User';

let ctrl;
class  CreateDuplicateListController {
  close: (params: { list: RawList }) => void;
  canCreate: boolean;
  name: string;
  userId: string;
  list: RawList[];
  venuesIds: any[];

  constructor(private $api, private $user, private unwrapError, private $scope, $injector) {
    'ngInject';
    this.canCreate = true;
    ctrl = this;
    ctrl.$analytics = $injector.get('$analytics');
  }

  $onInit() {
    this.name = this.list.name.concat(' (copy)');
    this.venuesIds = this.list.saved.map(v => v.venue._id.toString());
  }

  createGroup = () => {
    if (!this.name) {
      this.canCreate = false;
      return;
    }

    let userId;
    if (this.userId && this.$user.isAdmin()) {
      userId = this.userId;
    }

    return this.$api.Lists.createDuplicateList({ venues: this.venuesIds, name: this.name })
      .then((res) => {
        this.name = '';
        this.trackListCreationEvent(res.list);
        this.close({ list: res.list });
      })
      .catch(error => this.unwrapError(error));
  }

  trackListCreationEvent = (list) => {
    const eventName = ANALYTICS_EVENTS.list.createNewList;
    const eventParams = this.eventParamsHandler(list);
    const eventAction = 'creates new list';
    // works when a new list is created
    if (Object.keys(eventParams).length) {
      ctrl.$analytics.$trackEvent(eventName, eventParams, eventAction);
    }
  }

  eventParamsHandler = (list) => {
    const user = list.users[0].client;
    const params: { listName: string, userName: string, isPrimaryClient: boolean, company: string } = {
      listName: list.name,
      userName: UserHelpers.fullName(user),
      isPrimaryClient: list.users[0].isPrimary,
      company: user.companyName,
    };
    return params;
  }
}

export const CreateDuplicateListComponent = {
  template: require('./create-duplicate-list.component.jade'),
  controller: CreateDuplicateListController,
  bindings: {
    list: '=',
    close: '&'
  }
};
