// NPM Dependencies

import VenueSchema from 'common/dist/schemas/Venue';
import { each, findIndex, get, includes, set, last, cloneDeep, every, isEmpty } from 'lodash';
import moment from 'moment';

// constant
import { ANALYTICS_EVENTS } from '../../constants/ENUMS/analyticsEvents';
import { LITE_SPACE_STATUSES } from '../../../../database/constants/LiteSpaceStatuses';

export default ['$scope', '$user', '$timeout', '$api', '$routeParams', 'ngDialog', 'ENUMS', '$cloudinary', '$analytics', 'toast', 'unwrapError',
  '$rootScope', 'DocFactory', '$location', 'PathValidatorFactory', 'GooglePlacesFactory', 'editWinelistsModal', function($scope, $user, $timeout, $api, $routeParams, ngDialog, ENUMS,
  $cloudinary, $analytics, toast, unwrapError,
  $rootScope, DocFactory, $location, PathValidatorFactory, GooglePlacesFactory, editWinelistsModal) {
  $scope.amenityIndexByName = amenityIndexByName;
  $scope.addOnIndexByName = addOnIndexByName;
  $scope.completeAddress = completeAddress;
  $scope.enableAddressValidation = enableAddressValidation;
  $scope.findCancellationPolicy = findCancellationPolicy;
  $scope.hasCuisineType = hasCuisineType;
  $scope.hasPenalty = hasPenalty;
  $scope.last = last;
  $scope.openPhotos = openPhotos;
  $scope.openWinelistsModal = openWinelistsModal;
  $scope.saveDoc = saveDoc;
  $scope.setAddOnUIModel = setAddOnUIModel;
  $scope.setAmenityUIModel = setAmenityUIModel;
  $scope.setTaxes = setTaxes;
  $scope.toggleAddOn = toggleAddOn;
  $scope.toggleAmenity = toggleAmenity;
  $scope.toggleCuisineType = toggleCuisineType;
  $scope.validateAddress = validateAddress;
  $scope.addToDoc = addToDoc;
  $scope.cancel = cancel;
  $scope.reset = reset;
  $scope.submit = submit;
  $scope.deleteCard = deleteCard;
  $scope.edit = edit;
  $scope.updateList = updateList;
  $scope.hasAllTheRequiredFields = hasAllTheRequiredFields;
  $scope.toggleShowAllFields = toggleShowAllFields;
  $scope.toggleSkipReview = toggleSkipReview;
  $scope.toggleHideReview = toggleHideReview;
  $scope.accoladesTypes;
  $scope.years = [];
  $scope.accoladeDistinction = accoladeDistinction;
  $scope.getAccoladeRating = getAccoladeRating;
  $scope.updateYelpReview = updateYelpReview;
  $scope.currentYear = new Date().getFullYear().toString();
  $scope.endYear = 1990;

  $scope.dateNow;

  $scope.adminOps = {
    addCritique,
    displayDollarSigns,
    removeCritique,
    setPriceRange
  };

  $scope.errors = {};

  $scope.user = $user;

  $scope.accoladeIndex;
  $scope.videoIndex;

  $scope.hasErrors;
  $scope.invalidPath;

  $scope.showLiteProfileFields = $location.search().isActive === 'true' ? true : false;

  init();

  /**
   * Initialization logic - create a Venue document for validation
   * @set `$scope.doc`
   * @set `$scope.uiModel`
   */
  function init() {
    $scope.user.$waitFor('LOGGED_IN', function () {
      $scope.isAdmin = $scope.user.isAdmin();
      $rootScope.$emit('$viewReady', 'HIDE_FOOTER');
      $timeout(() => {
        $scope.$emit('UserVenueNewController');
      });
    });

    $scope.ENUMS = ENUMS;

    $scope.displayAccoladeForm = false;
    $scope.displayVideoForm = false;

    $scope.editAccolade = false;
    $scope.editVideo = false;

    $scope.videoTitles = ENUMS.videoTitles;
    $scope.videoAccountsTypes = ENUMS.videoAccountsTypes;

    $scope.uiModel = {
      amenities: {},
      addOns: {},
      cancellationPolicies: {}
    };

    $scope.accolade = {
      accolade: $scope.ENUMS.liteProfileAccolades.DISTINCTIONS[0],
      numberOfAccolades: null,
      accoladeRating: '',
      year: $scope.currentYear,
      imgUrl: '',
      accoladeUrl: ''
    };

    $scope.video = {
      videoPlayerType: ENUMS.videoAccountsTypes[0],
      title: ENUMS.videoTitles[0],
      thumbnail: '',
      url: '',
    };

    $scope.date = {
      now: moment(Date.now()).utc().format('YYYY-MM')
    };

    $scope.$cloudinary = $cloudinary;
    $scope.doc = new mongoose.Document($scope.Record, VenueSchema);
    $scope.leanDoc = $scope.Record;
    // Sets the error state management object
    $scope.pathValidator = PathValidatorFactory($scope.doc);

    getYears();

    getNeighborhoods()
      .then(data => $scope.uiModel.neighborhoods = data.neighborhoods);
  }
  // Public functions

  /**
   * Set amenity ui model based on a given amenity
   *
   * @params {String} amenity
   * @mutates `$scope.uiModel`
   */
  function setAmenityUIModel(amenity) {
    const index = $scope.amenityIndexByName(amenity);

    // Why not `angular.copy()` ?
    // Well it turns out deep copying data to not reference the object doesn't work well
    // with mongoose's magic functions and angular throws and error because of it.

    $scope.uiModel.amenities[amenity] = index !== -1
      ? { isChecked: true, item: $scope.doc.data.amenities[index].item, price: $scope.doc.data.amenities[index].price }
      : { isChecked: false };
  }

  function setAddOnUIModel(addOn) {
    const index = addOnIndexByName(addOn);

    $scope.uiModel.addOns[addOn] = index !== -1
      ? {
        isChecked: true,
        item: $scope.doc.data.addOns[index].item,
        price: $scope.doc.data.addOns[index].price,
        priceType: $scope.doc.data.addOns[index].priceType
      }
      : { isChecked : false };
  }

  function amenityIndexByName(amenity) {
    return findIndex($scope.doc.data.amenities, { item: amenity });
  }

  function addOnIndexByName(addOn) {
    return findIndex($scope.doc.data.addOns, { item: addOn });
  }

  function toggleAmenity(amenity) {
    const index = $scope.amenityIndexByName(amenity);

    if (index === -1) {
      $scope.doc.data.amenities = $scope.doc.data.amenities || [];
      $scope.doc.data.amenities.push({ item: amenity, price: 0 });
    } else {
      $scope.doc.data.amenities.splice(index, 1);
    }

    setAmenityUIModel(amenity);
  }

  function toggleAddOn(addOn) {
    const index = addOnIndexByName(addOn);

    if (index === -1) {
      $scope.doc.data.addOns = $scope.doc.data.addOns || [];
      $scope.doc.data.addOns.push({ item: addOn, price: 0 });
    } else {
      $scope.doc.data.addOns.splice(index, 1);
    }

    setAddOnUIModel(addOn);
  }

  function findCancellationPolicy(policyType) {
    return findIndex($scope.doc.data.terms.cancellationPolicies, { type: policyType });
  }

  function hasPenalty() {
    return findIndex($scope.doc.data.terms.cancellationPolicies,
      { type: ENUMS.cancellationPolicy.types['Penalty'] });
  }

  function toggleShowAllFields() {
    return $scope.showLiteProfileFields = !$scope.showLiteProfileFields;
  }

  function toggleSkipReview({ review }: { review: 'google' | 'yelp' }) {
    if (review === 'google') {
      $scope.doc.skipReview.google = !$scope.doc.skipReview.google;
    } else if (review === 'yelp') {
      $scope.doc.skipReview.yelp = !$scope.doc.skipReview.yelp;
    }
  }

  function toggleHideReview({ review }: { review: 'google' | 'yelp' }) {
    if (review === 'google') {
      $scope.doc.hideReview.google = !$scope.doc.hideReview.google;
    } else if (review === 'yelp') {
      $scope.doc.hideReview.yelp = !$scope.doc.hideReview.yelp;
    }
  }

  function updateYelpReview() {
    return $api.Admin.Venues.updateFreshYelpReview({ venueId: $scope.doc._id })
      .then((res) => {
        toast.goodNews('Request Sent', 'Yelp Update Request Sent Successfully');
      }).catch((err) => {
        toast.badNews('Error', 'Issue requesting yelp Update Request');
      });
  }

  /**
   * Determine if a venue has a given cuisine type in its array of cuisine types
   *
   * @param {String} type
   * @return {Boolean}
   */
  function hasCuisineType(type) {
    return includes($scope.doc.data.cuisineTypes, type);
  }

  /**
   * Add or remove a cuisine type from a venue's array of cuisine types
   *
   * @param {String} type
   */
  function toggleCuisineType(type) {
    const path = 'data.cuisineTypes';
    $scope.pathValidator.enableValidation(path);
    if (hasCuisineType(type)) {
      _removeCuisineType(type);
    } else {
      $scope.doc.data.cuisineTypes.push(type);
    }
    $scope.pathValidator.validatePath(path);
  }

  function findAnchorCity(details: { city: string, state: string, country: string }): string {
    const anchorCityName = [details.city, details.state, details.country].join(',');
    if ($scope.ENUMS.searchLocations[anchorCityName]) {
      return [details.state, details.country].join(',');
    }

    for (const key in $scope.ENUMS.searchLocations) {

      const matchesAnchorCity = $scope.ENUMS.searchLocations[key].aliases.find((alias) => {
        return alias.city === details.city && alias.state === details.state && alias.country === details.country;
      });

      if (matchesAnchorCity) {
        const [city, state, country] = key.split(',');
        return [state, country].join(',');
      }
    }
    return;
  }
  /**
   * Complete address based on the google places API
   *
   * @param {Details} details
   * @example {Details}
   *   {
   *     city: "New York"
   *     country: "United States"
   *     formatted: "123 W 20th St, New York, NY 10011, USA"
   *     latitude: 40.7416386
   *     longitude: -73.99516269999998
   *     neighborhood: "Midtown"
   *     number: "123"
   *     state: "NY"
   *     street: "W 20th St"
   *     zip: "10011"
   *   }
   */
  function completeAddress(details) {
    if (details.city) {
      $scope.setTaxes(details.city);
    }

    GooglePlacesFactory.setAddress($scope.doc.data, details);

    getNeighborhoods()
      .then(data => $scope.uiModel.neighborhoods = data.neighborhoods);

    const foundNeighborhood = $scope.doc.get('data.address.neighborhood');

    if (!$scope.uiModel.neighborhoods.includes(foundNeighborhood)) {
      $api.Locations.createNeighborhood($scope.doc.get('data.address'));
    }

    if ($scope.doc.isModified('data.address') && !foundNeighborhood) {
      $scope.doc.set('data.address.neighborhood', $scope.doc.get('data.address.city'));
    }
  }

  function getNeighborhoods () {
    const address = $scope.doc.get('data.address');
    return $api.Locations.getNeighborhoods(address)
      .catch(error => unwrapError(error));
  }

  function validateAddress() {
    each(['data.address.line1', 'data.address.city', 'data.address.state'], function (path) {
      $scope.pathValidator.validatePath(path);
    });
  }

  function enableAddressValidation() {
    each(['data.address.line1', 'data.address.city', 'data.address.state'], function (path) {
      $scope.pathValidator.enableValidation(path);
    });
  }

  /**
   * Add a critique to a venue's array of critiques
   */
  function addCritique() {
    $scope.doc.admin.critiques.push({});
    $scope.pathValidator.enableValidation('admin.critiques');
  }

  /**
   * Display an arbitrary number of dollar signs for admins to pick to reflect the
   * price point of a venue
   *
   * @param {Number} num
   * @return {String}
   */
  function displayDollarSigns(num) {
    let str = '';
    for (let i = 0; i < num; i++) {
      str += '$';
    }
    return str;
  }

  /**
   * Set the arbitrary price range for a venue, reflected in search page
   * @param {Number} num
   */
  function setPriceRange(num) {
    const priceRange = get($scope.doc, 'admin.priceRange');
    if (priceRange === num) {
      num = null;
    }
    set($scope.doc, 'admin.priceRange', num);
  }

  /**
   * Save the venue document and validate. There are multiple scenarios that we handle here:
   *
   * 1) If a venue is not visible and there are errors, allow the save to go through but don't make the venue visible
   * 2) If a venue is not visible and there are no errors, allow the save to go through and make the venue visible (but NOT published)
   * 3) If a venue WAS visible but there are now errors, disable the save button (this function shouldnt even run).
   * None of these conditions should apply to ADMIN properties or any fields beginning with `admin.`
   *
   * @param {Boolean} isVisible
   */
  function saveDoc({ shouldChangeUrl }: { shouldChangeUrl: boolean }) {
    // if venue was visible before this validation check and then errors, don't allow save
    const wasVisible = $scope.doc.isVisible;

    // booking-type can only changed by admin
    if ( $scope.Record.bookingType !== $scope.doc.bookingType && !$scope.isAdmin ) {
      return;
  }
    $scope.doc.isVisible = true;
    const authorizedUsers = $scope.leanDoc.authorizedUsers

      .filter(authUser => authUser.user)
      .map((authUser) => {
        return { user: get(authUser, 'user._id') ?  authUser.user._id : authUser.user, preferences: authUser.preferences, title: authUser.title };
      });

    $scope.doc.set('authorizedUsers', authorizedUsers);

    $scope.doc.isVisible = wasVisible;
    return $api.Venues.
      save($scope.doc, $scope.doc.status).
      then(function (data) {
        return $api.Venues.get(data.data.data.slug);
      })
      .then(function (data) {
        $scope.leanDoc = get(data, 'data.data.venue');
        const theVenue = get<{ slug: string }>(data, 'data.data.venue');
        const url = `/user/venues?venue=${theVenue.slug}&isActive=${$scope.showLiteProfileFields}`;
        if (shouldChangeUrl) {
          const eventName = ANALYTICS_EVENTS.venueDashboard.savedVenue;
          const eventParams = new $analytics.$Venue(theVenue);
          $analytics.$trackEvent(eventName, eventParams);
          $location.url(url);
        }
      })
      .catch ((error) => {
        if (!shouldChangeUrl) {
          $scope.errors = get(error, 'data.error.error');
          $scope.hasErrors = !isEmpty($scope.errors);
          $scope.invalidPath = $scope.hasErrors ? Object.keys($scope.errors)[0].split('.')[1] : '';
        }
        if (error && wasVisible) {
          const message = get($scope.errors, 'error') || 'Hey there, some of your venue information is invalid';
          toast.badNews('Venue Save Error', message);
          if (get($scope.errors, 'operation') === 'addVideo') {
            $scope.doc.data.videos.pop();
          }
          unwrapError(error);
        }
      });
  }

  /**
   * Opens a modal to allow user to edit a venue's photos
   */
  function openPhotos() {
    ngDialog.
      open({
        scope: $scope,
        template: require('./photo-modal.jade'),
        className: 'ngdialog-theme-plain',
        overlay: false,
        plain: true,
        controller: 'UserVenuesPhotos',
        resolve: {
          Record: function () {
            return { data: { data: $scope.doc.toObject() } };
          },
          // tslint:disable-next-line: no-shadowed-variable
          Model: ['$api', function ($api) {
            return $api.Venues;
          }]
        }
      })
      .closePromise
      .then(() => $api.Venues.get($routeParams.slug))
      .then(function (data) {
        const updatedVenue = data.data.data.venue;
        $scope.doc.data.photos = updatedVenue.data.photos;
        $scope.doc.data.coverIndex = updatedVenue.data.coverIndex;
      })
      .catch(unwrapError);
  }

  /**
   * Set a venue's F&B and nonF&B tax based on the city
   *
   * @param {String} city
   * NOTE: MUTATES `$scope.doc`
   */
  function setTaxes(city) {
    DocFactory.Venues.setTaxes($scope.doc, city);
  }

  /**
   * Removes a critique from a venue's array of critiques
   *
   * @param {Number} idx
   * NOTE: MUTATES `$scope.doc`
   */
  function removeCritique(idx) {
    $scope.doc.admin.critiques.splice(idx, 1);
    if (!get($scope.pathValidator, 'errorState.admin.critiques.shouldValidate')) {
      $scope.pathValidator.enableValidation('admin.critiques');
    } else {
      $scope.pathValidator.validatePath('admin.critiques');
    }
  }

  /**
   * Remove a cuisine type from a venue's array of cuisine types
   *
   * @param {String} type
   * @mutates `$scope.doc`
   */
  function _removeCuisineType(type) {
    const indexOfCuisineType = findIndex($scope.doc.data.cuisineTypes,
      cuisineType => cuisineType === type);
    if (indexOfCuisineType > -1) {
      $scope.doc.data.cuisineTypes.splice(indexOfCuisineType, 1);
    }
  }

  function openWinelistsModal() {
    return editWinelistsModal($scope.doc);
  }

  function cancel({ slug }) {
    if (slug === 'accolade') {
      $scope.displayAccoladeForm = false;
      $scope.reset('accolade');
    } else if (slug === 'video') {
      $scope.displayVideoForm = false;
      $scope.reset('video');
    }
  }

  function getYears() {
    for (let i = $scope.endYear; i <= $scope.currentYear; i++) {
      $scope.years.push(i);
    }
  }

  function accoladeDistinction(accolade) {
    if (Object.keys($scope.ENUMS.liteProfileAccolades.MAPPED_DISTINCTIONS).includes(accolade.accolade)) {
      $scope.accoladesTypes = $scope.ENUMS.liteProfileAccolades.MAPPED_DISTINCTIONS[accolade.accolade];
      $scope.accolade.accoladeRating = $scope.accoladesTypes[0].name;
      $scope.accolade.imgUrl = $scope.accoladesTypes[0].imgUrl;
      $scope.accolade.numberOfAccolades = $scope.accoladesTypes[0].rating;
    }
  }

  function getAccoladeRating(accolade) {
    const data = $scope.accoladesTypes.find(obj => obj.name === accolade.accoladeRating);
    $scope.accolade.numberOfAccolades = data.rating;
    $scope.accolade.imgUrl = data.imgUrl;
  }

  function reset(slug) {
    if (slug === 'accolade') {
      $scope.accolade.accolade = $scope.ENUMS.liteProfileAccolades.DISTINCTIONS[0],
      $scope.accolade.numberOfAccolades = null,
      $scope.accolade.accoladeRating = '',
      $scope.accolade.year = $scope.currentYear,
      $scope.accolade.imgUrl = '';
      $scope.accolade.accoladeUrl = '';
    } else if (slug === 'video') {
      $scope.video.videoPlayerType = $scope.ENUMS.videoAccountsTypes[0],
      $scope.video.title = $scope.ENUMS.videoTitles[0],
      $scope.video.thumbnail = '',
      $scope.video.url = '';
    }
  }

  function addToDoc({ slug }) {
    if (slug === 'video') {
      $scope.displayVideoForm = true;
      $scope.editVideo = false;
    } else if (slug === 'accolade') {
      $scope.displayAccoladeForm = true;
      $scope.editAccolade = false;
    }
    $scope.reset(slug);
  }

  function submit({ slug }) {
    if (slug === 'accolade') {
      $scope.editAccolade ? $scope.updateList('accolades') : $scope.doc.data.accolades.push($scope.accolade);
      $scope.displayAccoladeForm = false;
      $scope.editAccolade = false;
    } else if (slug === 'video') {
      $scope.editVideo ? $scope.updateList('videos') : $scope.doc.data.videos.push($scope.video);
      $scope.displayVideoForm = false;
      $scope.editVideo = false;
    }
    $scope.saveDoc({ shouldChangeUrl: false });
  }

  function updateList(toUpdate) {
    if (toUpdate === 'accolades') {
      $scope.doc.data.accolades[$scope.accoladeIndex] = $scope.accolade;
    } else if (toUpdate === 'videos') {
      $scope.doc.data.videos[$scope.videoIndex] = $scope.video;
    }
  }

  function deleteCard({ slug, index }: { slug: string, index: number }) {
    if (slug === 'accolade') {
      this.doc.data.accolades.splice(index, 1);
    } else if (slug === 'video') {
      this.doc.data.videos.splice(index, 1);
    }
    $scope.saveDoc({ shouldChangeUrl: false });
  }

  function edit({ slug, index }: { slug: string, index: number }) {
    if (slug === 'accolade') {
      $scope.leanDoc.data.accolades[index].year = $scope.leanDoc.data.accolades[index].year;
      $scope.accolade = cloneDeep($scope.leanDoc.data.accolades[index]);
      $scope.editAccolade = true;
      $scope.accoladeIndex = index;
      $scope.displayAccoladeForm = true;
    } else if (slug === 'video') {
      $scope.video =  cloneDeep($scope.leanDoc.data.videos[index]);
      $scope.editVideo = true;
      $scope.videoIndex = index;
      $scope.displayVideoForm = true;
    }
  }

  function hasAllTheRequiredFields({ slug }) {
    let requiredFields;
    if (slug === 'accolade') {
      requiredFields = [
        get($scope.accolade, 'accolade'),
        get($scope.accolade, 'accoladeRating'),
        get($scope.accolade, 'year'),
      ];
    } else if (slug === 'video') {
      requiredFields = [
        get($scope.video, 'title'),
        get($scope.video, 'url')
      ];
    }
    return every(requiredFields);
  }
}];

