import LeadHelpers from 'common/dist/virtuals/Lead';
import moment from 'moment';
import price from 'common/dist/price';
import { get, findIndex } from 'lodash';
import { RecosService } from 'spc/recos/recos.service';
import { ApiService } from 'spc/shared/api/api.service';

import { RawLead } from 'lib/database/types/lead';
import { getCityNameFromValue } from 'spc/utils/getCityDisplayName';

class ClientDashboardListController {
  showFullList: any = {};
  concludedEvents: boolean;
  selectedTab: string;
  leads: RawLead[];
  archivedLeads: RawLead[];
  showArchive: boolean;
  loading: boolean;
  splitLeads: boolean;
  memberConfirmedLeads: RawLead[] = [];
  memberConcludedLeads: RawLead[] = [];
  confirmedLeads: RawLead[] = this.memberConfirmedLeads || [];
  concludedLeads: RawLead[] = this.memberConcludedLeads || [];
  getCityNameFromValue = getCityNameFromValue;
  isSortAscending: boolean;

  constructor(private $api: ApiService, private ENUMS, private $cloudinary, private $clickOk, private unwrapError, private archiveEventModal, private $window, private $location) {
    'ngInject';
  }

  $onInit = () => {
    if (this.splitLeads) {
      this.leads.forEach((lead) => {
        const yesterday = moment().subtract(1, 'day');
        if (lead['eventDate'] > yesterday) {
          this.confirmedLeads.push(lead);
        } else if (lead['eventDate'] < yesterday) {
          this.concludedLeads.push(lead);
        }
      });
    }
  }

  displayGuests = (numGuests) => {
    return numGuests.min && numGuests.max ?
      `${numGuests.min} - ${numGuests.max}` :
      numGuests.min ? `${numGuests.min} min` : `${numGuests.max} max`;
  }

  recommendationState = (recommendation) => {
    if (recommendation.conversation) {
      return recommendation.conversation.requestState;
    } else {
      return 'Recommended';
    }
  }
  getUnarchivedLead = (leads) => {
    return leads.filter(lead => !lead.archived);
  }

  getLondonBudget = (budget) => {
      return '£' + budget.toLocaleString();
    }

  archiveEvent = (lead) => {
    const activeProposalStates = this.ENUMS.bookingRequestState.activeProposalStates;
    const activeProposal: number = lead.recommendations.filter(reco => activeProposalStates.includes(get(reco, 'conversation.request.state'))).length;
    const isProposalWithDeposit = lead.recommendations.some(reco => get(reco, 'conversation.request.state') === 'DEPOSIT');
    let message;
    let buttonText;
    if (isProposalWithDeposit) {
      message = `You have already paid a deposit to a venue. Please contact the venue to cancel the event before archiving.`;
      buttonText = 'Contact Venue';
      const showCancel = false;
      return this.$clickOk(message, showCancel);

    } else {
      message = activeProposal ? `Are you sure you want to archive this event? You have ${activeProposal} live proposal` + (activeProposal > 1 ? 's ' : ' ') + `associated with it. Archiving this event will cancel these proposals and notify venues of your cancellation` : `Are you sure you want to archive this event?`;
      const showCancel = true;

      return this.$clickOk(message, showCancel)
        .then((response) => {
          if (get(response, 'value.cancel')) {
            return;
          }
          return this.archiveEventModal(lead)
            .then((res) => {
              if (res.value && res.value.isArchived === true) {
                const index = findIndex(this.leads, l => l._id === lead._id);
                this.leads.splice(index, 1);
                this.archivedLeads.push(res.value.updatedLead);
              }
            });
        })
        .catch(error => this.unwrapError(error));
    }
  }

  leadVenues = (lead) => {
    return lead.recommendations.length;
  }

  isRecoBooked = (reco) => {
    return this.ENUMS.bookingRequestState.paidStates.includes(get(reco, 'conversation.requestState'));
  }

  recosLength = (recoArray) => {
    return recoArray.filter(reco => !reco.archived).length;
  }

  openLeadPage = (lead) => {
    const url = `/recos/${lead._id.toString()}`;
    window.open(url);
  }

  openContract = (reco) => {
    const url = `/client/conversation/${reco.conversation._id.toString()}`;
    window.open(url);
  }

  canOpenContract = (reco) => {
    if (reco.conversation.request.state !== 'Proposal') {
      return this.openContract(reco);
    }
  }

  getCollaboratorInitials = (user) => {
    return `${user.profile.name.first[0].toUpperCase()}${user.profile.name.last[0].toUpperCase()}`;
  }

  getSortOrderForInPlayAndAll = () => {
    if (this.isSortAscending) {
      return '+eventDate';
    }
    return '-eventDate';
  }
}
export const ClientDashboardListComponent = {
  controller: ClientDashboardListController,
  template: require('./client-dashboard-list.component.jade'),
  bindings: {
    leads: '=',
    showArchive: '<',
    archivedLeads: '=',
    loading: '<',
    splitLeads: '<',
    memberConfirmedLeads: '<',
    memberConcludedLeads: '<',
    isSortAscending: '<'
  }
};
