import { ApiService } from 'spc/shared/api/api.service';
import { UserService } from 'spc/services/user.service';

import { RawList } from 'spc/lib/database/types/list';
import { DBaseUser, RawBaseUser } from 'spc/lib/database/types/base-user';
import { includes } from 'lodash';
import debounce from 'lodash/debounce';

interface Link {
  name: string;
}

class ListsDashboardController {
  componentIsReady: () => any;
  user: RawBaseUser;
  userId: string;
  lists: RawList[];
  links: Link[];
  members: DBaseUser[];
  componentView: string = 'Card View';
  stateManager: { tab: string; };
  archivedLists: RawList[];
  loaderState: string;
  hasArchived: boolean = false;
  hasAccount: boolean = false;
  totalLists: number;
  currentPage: number;
  listCreators: any;
  filters: {
    creator: string[],
    sort: any,
    text: string
  } = { creator: [], sort: '', text: '' };
  creators: string[];
  sortParameters: { key: string, value: {} }[] =  [
    { key: 'Name (A-Z)', value: { name: 1 } },
    { key: 'Name (Z-A)', value: { name: -1 } },
    { key: 'Created (Newest)', value: { createdAt: -1 } },
    { key: 'Created (Oldest)', value: { createdAt: 1 } }];

  loading: boolean = false;

  constructor(
    private $user: UserService,
    private $api: ApiService,
    private unwrapError,
    private $scope,
    private createListModal,
    private archiveListModal,
    private $cloudinary,
    private $clickOk
  ) {
    'ngInject';
    $scope.$on('CHANGE_PARENT_STATE', function(event, args) {
        $scope.currentState = args.state;
    });

    $scope.isState = function isState(state) {
        return $scope.currentState === state;
    };
  }

  $onInit() {
    this.componentIsReady();
    this.user = !!this.$user.$ ? this.$user.$ : null;

    if (this.user.account) {
      this.accountMembershipDetails();
      this.hasAccount = true;
    }
    this.links = [
      { name: 'Your Lists' },
    ];

    if (this.hasAccount) {
      this.links.push( { name: 'Team Lists' });
    }

    this.stateManager = {
      tab: 'Card View'
      };
    this.user = this.$user.$;
    this.userId = this.user._id.toString();
    this.loading = true;
    this.fetchUserLists();
    this.accountMembershipDetails();
  }

  changeTab = (viewType) => {
    if (!includes(['Card View', 'Table View'], viewType)) {
      this.unwrapError(new Error(`Invalid view type ${viewType}`));
    }
    if (this.stateManager.tab && viewType === this.stateManager.tab) {
      return;
    } else {
      return this.stateManager.tab = viewType;
    }
  }
  isCreatorSelected = (creator) => {
    return this.filters.creator.includes(creator);
  }

  fetchUserLists(idx = 0) {
    this.currentPage = idx + 1;
    this.loading = true;
    this.lists = [];
    this.$api.Lists.getAll({ userId: this.userId, page: idx , filters: this.filters })
      .then((res: any) => {
        this.lists = res.lists.data;
        this.creators = res.lists.creators;
        this.archivedLists = res.lists.archivedData;
        this.totalLists = res.lists.totalListCount;
        this.loading = false;
      })
      .catch(error => this.unwrapError(error));
  }

  debouncedListSearch = debounce(this.fetchUserLists, 600);

  noFilterApplied = () => !this.filters.creator && !this.filters.text;

  setSorting = (param) => {
    this.filters.sort = param.value;
    this.fetchUserLists();
  }
  setCreatorFilter = (creator) => {
    if (!this.isCreatorSelected(creator)) {
      this.filters.creator.push(creator);
    }
    else {
      this.filters.creator = this.filters.creator.filter((element) => {
        return element !== creator;
      });
    }
    this.fetchUserLists();
  }

  accountMembershipDetails = () => {
    return this.$api.MembershipAccount.getAccount()
      .then((res) => {
        this.members = res.account.members as DBaseUser[];
      })
      .catch((error) => {
          this.unwrapError(error);
      });
  }

  archiveList (list) {
    list.isVisible = false;
    return this.$api.Lists.toggleListVisibility(list)
      .then(() => {
        this.fetchUserLists();
      })
      .catch(error => this.unwrapError(error));
  }

  openArchiveListModal() {
    return this.archiveListModal(this.archivedLists)
      .then(() => {
        this.fetchUserLists();
      })
      .catch(error => this.unwrapError(error));
  }

  openCreateListModal() {
    return this.createListModal(this.lists)
      .then((res) => {
        if (res.value.list) {
          this.lists.push(res.value.list);
        }
      })
      .catch(error => this.unwrapError(error));
  }

}

export const ListsDashboardComponent = {
  controller: ListsDashboardController,
  template: require('./lists-dashboard.component.jade'),
  bindings: {
    componentIsReady: '&',
  }
};
