
import { get } from 'lodash';
import { ReviewsService } from 'spc/reviews/reviews.service';
import VenueHelpers from 'common/dist/virtuals/Venue';

/**
 * Presentational Component to display the HTML for the space window that appears on the google map
 * on the search page for when the view type is SPACE
 */
let ctrl;
class SpaceInfoWindowComponent {
  constructor($cloudinary) {
    'ngInject';
    ctrl = this;
    ctrl.$cloudinary = $cloudinary;
  }

  fetchAndConvertURL(venue, imgType = 'thumb') {
    return ctrl.$cloudinary.fetchAndConvertURL(venue.data.photos[venue.data.coverIndex].url, imgType);
  }

  srcSet(venue) {
    return ctrl.$cloudinary.srcSet(venue.data.photos[venue.data.coverIndex].url);
  }

  minimumMenuPriceCents(venue) {
    return VenueHelpers.minimumMenuPriceCents(venue);
  }

  displaySpaceCapacity(space) {
    return space.data.capacity.standing.max
      ? space.data.capacity.seated.min + ' - ' + space.data.capacity.standing.max
      : space.data.capacity.seated.min + ' - ' + space.data.capacity.seated.max;
  }

  getRating(venue) {
    return get(ctrl.search, `ratings.${venue._id.toString()}.venueRecommendation`);
  }

  showRating(venue){
    return get<number>(ctrl.search, `ratings.${venue._id.toString()}.ratingCount`, 0) > 5;
  }

  getCount(venue) {
    return get<number>(ctrl.search, `ratings.${venue._id.toString()}.ratingCount`, 0);
  }

  /**
   * Get venue view url for a venue and preload a space with any availability
   * check queries based on the search filters
   * 
   * @public
   * @param {Object} venue
   * @param {String} spaceId
   * @return {String}
   */
  getVenueViewUrl(venue, spaceId) {
    return ctrl.search.getVenueViewUrl(venue, spaceId);
  }
}

export default {
  bindToController: true,
  bindings: {
    venue: '<',
    ambianceClass: '&',
    search: '<'
  },
  controller: SpaceInfoWindowComponent,
  template: require('./space-info-window.jade')
};
