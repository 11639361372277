import { privacyTypes } from 'common/dist/enums/concierge';
import { RawLead, LeadRequest } from 'spc/lib/database/types/lead';
import time from 'common/dist/time';
import moment from 'moment';
import ENUMS from 'common/dist/enums';
import { perGuestBudgetsLondon } from '../../../../database/constants/londonConstants';
import { cloneDeep, get, set, every } from 'lodash';
import AnalyticsService from 'spc/shared/analytics/analytics.service';
import debounce from 'lodash/debounce';
import { RawBaseUser } from 'spc/lib/database/types/base-user';
import { CityTzMapperService } from '../../shared/cityTZmapper.service';
import { getCityNameFromValue } from 'spc/utils/getCityDisplayName';

interface Location {
  name: string;
  premiumCity: boolean;
  slug?: string;
}

class CreateEditEventController {
  eventTypes: [{ label: string, visible: boolean }];
  close: (response) => void;
  trackEvent: ({ lead, client }: { lead: any, client: any }) => void;
  client: RawBaseUser;
  editEvent: boolean;
  status: boolean;
  editableLead;
  preFilledData;
  leadValidationError;
  avTypes;
  lead: {
    name: string;
    request: LeadRequest
  } = {
    name: '',
    request: {}
  };

  privacy: string[];
  selectedPrivacy: string[];
  locations: Location[];
  ui: {
    startTimeOptions?: string[];
    endTimeOptions?: string[];
  } = {};

  search: {
    startTime?: number;
    endTime?: number;
  } = {
    startTime: get(this.lead, 'request.time')
  };
  cuisineTypes: string[];
  selectedCuisine: string[];
  selectedAv: string[];
  city: string;
  date: string;
  budgetPerPerson: string[];
  showOptional: boolean;
  loading: boolean = false;
  display: { cuisineMessage?: string, locationMessage?: string } = {};
  showPrivacy: any;
  showCuisine: any;
  showAv: any;
  constructor(
    private $api,
    private $analytics: AnalyticsService,
    private $user,
    private ENUMS,
    private unwrapError,
    private $scope,
    private selectionModal,
    private cityTzMapperService: CityTzMapperService
    ) {
    'ngInject';
    this.privacy = privacyTypes;
  }
  $onInit() {
    this.selectedPrivacy = [];
    this.selectedCuisine = [];
    this.selectedAv = [];
    if (this.editEvent) {
      this.lead = this.editableLead;
      this.selectedPrivacy = this.lead.request.privacy;
      this.city = getCityNameFromValue(this.lead.request.city) || this.lead.request.city;
      this.selectedCuisine = this.lead.request.preferredCuisineType;
      this.selectedAv = this.lead.request.AV.options;
    }
    if (get(this.preFilledData, 'venueAddress')) {
      this.setPrefilledData();
    }
    this.setDateTime();
    this.eventTypes = this.ENUMS.eventType.filter(type => type.visible);
    this.showOptional  = this.editEvent ? true : false;
    this.setClient();
    this.setTimeSelectOptions();
    this.setLocation();
    this.cuisineTypes = ENUMS.cuisineTypes;
    this.budgetPerPerson = this.city === 'London' ? perGuestBudgetsLondon : ENUMS.concierge.perGuestBudgets;
    this.avTypes = this.ENUMS.concierge.avOptions;
  }

  setPrefilledData = async () => {
    try {
      const venueParentCity = await this.$api.Locations.fetchParentCity(this.preFilledData.venueAddress);
      this.city = getCityNameFromValue(get(venueParentCity, 'city') || get(this.preFilledData, 'venueAddress.city'));
      this.lead.request.city = get(venueParentCity, 'city') || get(this.preFilledData, 'venueAddress.city');
    } catch (error) {
      this.unwrapError(error);
    }
  }

  getMinDate = () => {
    return this.$user.isAdmin() ? this.lead.createdAt : null;
  }

  setLocation = async() => {
    const isPremium = await this.$user.isPremiumMember();
    this.locations = isPremium ? this.ENUMS.acceptableUserCities : this.ENUMS.acceptableUserCities.filter(city => !city.premiumCity && city.name !== 'Virtual' ) ;
  }


  openPrivacy = () => {
    this.showPrivacy = true;
  }

  closePrivacy = () => {
    this.showPrivacy = false;
  }

  openCuisine = () => {
    this.showCuisine = true;
  }

  closeCuisine = () => {
    this.showCuisine = false;
  }

  openAv = () => {
    this.showAv = true;
  }

  closeAv = () => {
    this.showAv = false;
  }

  setDateTime = () => {
    this.date = get(this.lead, 'request.date') ? moment(this.lead.request.date).local().format('YYYY-MM-DD') : null;
    this.search.startTime = get(this.lead, 'request.time');
    this.search.endTime = time.getEndTimeFromStartTimeAndDuration(this.search.startTime, this.lead.request.duration);
  }

  toggleShowOptional = () => {
    this.showOptional = ! this.showOptional;
  }

  setCuisine = (cuisine) => {
    const index = this.selectedCuisine.indexOf(cuisine);
    if (index > -1) {
      this.selectedCuisine.splice(index, 1);
    }
    else {
      this.selectedCuisine.push(cuisine);
    }
  }
  setPrivacy = (privacy) => {
    const index = this.selectedPrivacy.indexOf(privacy);
    if (index > -1) {
      this.selectedPrivacy.splice(index, 1);
    }
    else {
      this.selectedPrivacy.push(privacy);
    }
  }
  setAv = (av) => {
    const index = this.selectedAv.indexOf(av);
    if (index > -1) {
      this.selectedAv.splice(index, 1);
    }
    else {
      this.selectedAv.push(av);
    }
  }

  createMsg = (items) => {
    if (items.length > 1) {
      return `${items[0]} and ${items.length - 1}  other` + ((items.length - 1 > 1) ? 's' : '');
    }
    return items[0];
  }

  isFilterEnabledArray = (path, value) => {
    return path.includes(value);
  }

  toggleAv = () => {
    this.lead.request.AV.selected = !this.lead.request.AV.selected;
  }
  selectBudget = (budget) => {
    this.lead.request.budgetPerGuest = budget;
  }
  setTimeSelectOptions = () => {
    this.ui.startTimeOptions = time.getTimeSelectOptions(15);
    if (get(this.lead, 'request.date', '').toString() === moment().format('YYYY-MM-DD')) {
      const currentTime = moment().hours() * 100 + moment().minutes();
      this.ui.startTimeOptions = this.ui.startTimeOptions.filter(time => time.value > currentTime);
    }
    if (get(this.search, 'startTime')) {
      this.ui.endTimeOptions = time.getTimeSelectOptions(15)
        .filter(time => time.value > this.search.startTime);
    }
  }

  timeSelected = () => {
    this.setTimeSelectOptions();
  }

  dateSelected = (date) => {
    this.lead.request.date = date;
    this.setTimeSelectOptions();
  }

  setClient = () => {
    if (!this.$user.$) {
      return;
    }
    this.client = cloneDeep(this.$user.$);
  }
  selectOccasion = (occasion) => {
    set(this.lead, 'request.occasion', occasion);
  }
  canSubmit = () => {
    const requiredFields = [
      get(this.lead, 'name'),
      get(this.lead, 'request.occasion'),
      get(this.lead, 'request.city')
    ];
    return every(requiredFields);
  }

  setLocationDetails = (details) => {
    set(this.lead, 'request.locationDetails.city', this.getGooglePlacesCity(details));
    set(this.lead, 'request.locationDetails.state', details.state);
    set(this.lead, 'request.locationDetails.country', details.country);
    set(this.lead, 'request.locationDetails.zipCode', details.zip);
    set(this.lead, 'request.locationDetails.mapUrl', details.url);
  }

  getGooglePlacesCity = (details) => {
    if (details.city) {
      return details.city;
    } else if (details.sublocality) {
      return details.sublocality;
    } else if (details.vicinity) {
      return details.vicinity;
    }
  }

  selectCity = (city) => {
    this.city = city.name;
    this.lead.request.city = city.value;
    if (this.city === 'London') {
      this.budgetPerPerson =  perGuestBudgetsLondon;
    }
  }

  createEditEvent = () => {
    this.loading = true;

    this.$api.Leads.validate({ lead: this.lead, client: this.client })
      .then((res) => {
        if (res.errors.lead) {
          if (res.errors.lead && res.errors.lead['request.numGuests.max'].message) {
            this.leadValidationError = `Max Guest count cannot be less than Min Guest Count.`;
          }
          this.unwrapError(res.errors.lead);
        }
      })
      .catch(error => this.unwrapError(error));
    set(this.lead, 'request.privacy', this.selectedPrivacy);
    set(this.lead, 'request.preferredCuisineType', this.selectedCuisine);
    set(this.lead, 'request.AV.options', this.selectedAv);
    set(this.lead, 'request.time', this.search.startTime);
    set(this.lead, 'request.duration', time.getDurationFromTimes(this.search.startTime, this.search.endTime));
    if (get(this.lead, 'request.occasion') === 'Other' && !get(this.lead, 'request.customOccasion')) {
      set(this.lead, 'request.customOccasion', this.lead.name);
    }
    const leadCity = this.lead.request.city;
    // const leadDate = moment(this.lead.request.date).format('YYYY-MM-DD');
    const leadDate = this.lead.request.date ? moment(this.lead.request.date).format('YYYY-MM-DD') : '';
    if (leadCity && leadDate) {
      const tzMappedDate = this.cityTzMapperService.getCityTZmappedDate({ city: leadCity, date: leadDate });
      this.lead.request.date =  tzMappedDate ? tzMappedDate : this.lead.request.date;
    }
    if (this.editEvent) {
      return this.$api.Leads.update({ lead: this.lead })
        .then((response) => {
          this.loading = false;
          this.close({ response: response });
        })
        .catch((error) => {
          this.loading = false;
          this.unwrapError(error);
      });
    }
    set(this.lead, 'admin.createdThrough', 'Client');
    set(this.lead, 'eventType', 'shellEvent');
    return this.$api.Leads.create({ client: this.client, lead: this.lead })
      .then((response) => {
        this.loading = false;
        this.close({ response: response });
      })
      .catch((error) => {
        this.loading = false;
        this.unwrapError(error);
      });
  }
}

export const CreateEditEventComponent = {
  template: require('./create-edit-event.component.jade'),
  controller: CreateEditEventController,
  bindings: {
    close: '&',
    editEvent: '<',
    editableLead: '<',
    preFilledData: '<',
  }
};
