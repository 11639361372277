import get from 'lodash/get';

import { ApiService } from 'spc/shared/api/api.service';
import { RawBookingRequest } from 'spc/lib/database/types/booking-request';
import { primaryEventContact } from 'common/dist/virtuals/AuthorizedClient';
import { UserService } from 'spc/services/user.service';
import { ReviewData } from 'spc/lib/server/api/requests/models';

interface Errors {
  [key: string]: { kind: 'required' } | string;
}

class SubmitReviewController {
  ui: {
    venueName?: string
    submitSuccessful: boolean,
    count?: number;
  } = { submitSuccessful: false };
  errors: Errors;
  review: ReviewData = {
    venueRecommendation: null,
    sixplusRecommendation: null,
    venuePermission: true
  };
  request: RawBookingRequest;
  loading: boolean = false;
  constructor(
    private $rootScope,
    private $routeParams,
    private $api: ApiService,
    private $user: UserService,
    private $location: ng.ILocationService,
    private $scrollService,
    private unwrapError,
    private $interval) {
    'ngInject';
  }

  $onInit = () => {
    const conversationId = this.$routeParams.conversation;

    this.$api.Requests.getReview(conversationId)
      .then((data) => {
        const review = data.review;
        if (review) {
          this.handleExistingReview(review);
        }
        this.request = data.request;
        this.ui.venueName = this.request.venue.data.name;
        this.handleUnauthorized();
        this.$rootScope.$emit('$viewReady', 'SHOW_FOOTER');
      })
      .catch((error) => {
        if (error.status === 400) {
          this.redirectToUnauthorized();
        }
        this.unwrapError(error);
      });
  }

  shouldSubmitReview = () => {
    return this.review.venuePublicComment && this.review.food && this.review.service && this.review.ambience && this.review.communication && this.review.venueRecommendation;
  }

  submitReview = () => {
    this.loading = true;
    this.review.venue = this.request.venue._id.toString();
    this.review.eventDate = this.request.data.date;
    this.$scrollService('#reviews-submit');
    this.$api.Requests.submitReview({
      conversation: this.request.conversation.toString(),
      review: this.review
    })
      .then(() => {
        this.ui.submitSuccessful = true;
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        this.errors = get<Errors>(error, 'data.error.errors', {});
        this.errors['message'] = get<string>(error, 'data.error.message');
        this.unwrapError(error);
      });
  }

  redirectToUnauthorized() {
    this.$location.url('/unauthorized');
  }

  handleUnauthorized = () => {
    this.$user.$waitFor('LOGGED_IN', () => {
      if (this.$user.isAdmin()) {
        return true;
      }

      const isUserOnProposal = !!this.request.clients.find(client => client.user.toString() === this.$user.$._id.toString());

      if (!isUserOnProposal) {
        this.$location.url('/unauthorized');
      }

    });
  }

  handleExistingReview = (review) => {
    this.ui.submitSuccessful = true;
  }
}

export const submitReviewComponent = {
  controller: SubmitReviewController,
  template: require('./submit-review.component.jade'),
};
