export const starsComponent = {
  template: require('./stars.component.jade'),
  bindings: {
    stars: '<',
    label: '@',
    count: '<',
    score: '<'
  }
};

