import { RecommendationService } from 'spc/shared/recommendation.service';
import { Recommendation } from 'spc/lib/server/api/admin/leads/models';

class RecoFeedbackController {
  comment: string;
  shouldArchiveReco: boolean = true;
  recommendation: Recommendation;
  close: (reco?) => any;
  constructor(private recommendationService: RecommendationService, private $q, private unwrapError) {
    'ngInject';
  }

  submit = () => {
    let recoChanges = {};
    if (this.comment) {
      recoChanges = this.recommendationService.createCommentChanges(this.recommendation, { value: this.comment });
    }
    if (this.shouldArchiveReco && this.recommendationService.allowArchive(this.recommendation)) {
      recoChanges['archived'] = true;
    }

    if (Object.keys(recoChanges).length) {
      this.recommendationService.updateRecommendation({ recommendation: this.recommendation, changes: recoChanges })
        .then(reco => this.close(reco))
        .catch(error => this.unwrapError(error));
    } else {
      this.close();
    }
  }
}

export const RecoFeedbackComponent = {
  template: require('./reco-feedback.component.jade'),
  controller: RecoFeedbackController,
  bindings: {
    close: '&',
    recommendation: '<'
  }
};

