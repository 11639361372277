interface Menu {
  _id: string;
  data: {
    type: string;
    priceInCents: number;
  };
}

interface Range {
  min: number;
  max: number;
}

interface Venue {
  data: {
    videos: [
      { title: string }
    ]
  };
}

export class ResultCardService {
  constructor(private KEY_TO_ABBREVIATION_MAPPER) {
    'ngInject';
  }

  displayRanges = (menus: Menu[]): string => {
    let ret = '';
    const typeRanges = this.getMenuRanges(menus);
    for (const key in this.KEY_TO_ABBREVIATION_MAPPER) {
      const { min, max } = typeRanges[key];
      ret = ret.concat(this.getCopy({ min, max, key }));
    }
    return ret;
  }

  getMenuRanges = (menus: Menu[]): any => {
    const typeRanges = {};
    Object.keys(this.KEY_TO_ABBREVIATION_MAPPER).forEach((menuType: string) => typeRanges[menuType] = { min: null, max: null });
    menus.forEach((menu) => {
      const type = menu.data.type;
      const price = menu.data.priceInCents;
      typeRanges[type] = this.getRange({ range: typeRanges[type], price });
    });
    return typeRanges;
  }

  getRange = ({ range, price }: { range: Range, price: number }): Range => {
    let { min, max } = range;
    if (!price) {
      return range;
    }
    if (!min) {
      min = price;
    }
    if (!max) {
      max = price;
    }
    max = Math.max(price, max);
    min = Math.min(price, min);
    return { min, max };
  }

  getCopy = ({ key, min, max }: { key: string, min: number, max: number }): string => {
    if (!min && !max) {
      return `${this.KEY_TO_ABBREVIATION_MAPPER[key]}\n -- \n\n`;
    }
    if (min === max) {
      return `${this.KEY_TO_ABBREVIATION_MAPPER[key]}\n $${min / 100} \n\n`;
    }
    return `${this.KEY_TO_ABBREVIATION_MAPPER[key]}\n $${min / 100} - $${max / 100} \n\n`;
  }

  showVideoIcon({ venue, isPremiumUser }: { venue: Venue, isPremiumUser: boolean }) {
    let videoIcon = false;
    const hasVideos = venue.data.videos;
    if (hasVideos.length) {
      hasVideos.forEach((video) => {
        if (isPremiumUser || !isPremiumUser && video.title === 'Teaser') {
          videoIcon = true;
        }
      });
    }
    return videoIcon;
  }
}
