// NPM Dependencies
import commafy from 'commafy';
import moment from 'moment';
import { each, get, includes, isNumber, last, set } from 'lodash';
import BookingRequestHelpers from 'common/dist/virtuals/BookingRequest';
import AuthorizedClientHelpers from 'common/dist/virtuals/AuthorizedClient';
import { fullPriceBreakdownCents } from 'common/dist/price';
import { getTimeSelectOptions } from 'common/dist/time';
import UserHelpers from 'common/dist/virtuals/User';
import status from 'http-status';

// SixPlus Dependencies (CommonJS)
import { ApiService } from 'spc/shared/api/api.service';
import { MessageService } from 'spc/requests/messages/message.service';
import { ToastService } from '../../shared/toast.service';
import { roleChecker } from '../../utils/roleChecker';
import { getAttachments } from './guest-venue-conversation';

import { StateEmitter } from '../../utils/StateEmitter';
import { RawBookingRequest } from '../../../../database/types/booking-request';
import { QuillInsert } from '../../../../server/api/conversations/models';
import { TransmittedAttachment } from 'spc/lib/database/types/attachments';
import { VENUE_TEMPLATES } from '../../../../database/constants/venueTemplate';
import price from 'common/dist/price';

module.exports = function () {
  return {
    template: require('./venue-conversation.jade'),
    link: function (scope, elem, attrs) {
      scope.scrollToTop = scrollToTop;

      scope.$on('SCROLL_TO_FIELD', (ev, params) => {
        const field = params.element;
        if (field) {
          const height = field.scrollTop() + 350;
          $('html, body').animate({ scrollTop: height }, 500);
        }
      });

      function scrollToTop() {
        $('html, body').animate({ scrollTop: 0 }, 500);
      }
    },
    controller: [
      '$scope',
      '$api',
      'ENUMS',
      '$counteroffer',
      '$clickOk',
      'REQUEST_STATES',
      '$cloudinary',
      '$timeout',
      'addClientsModal',
      '$seo',
      'unwrapError',
      'messageService',
      '$scrollService',
      'toast',
      'proposalContactsModal',
      'proposalHelpModal',
      'adminManageModal',
      '$user',
      '$location',
      'messageAttachmentsModal',
      'attachmentsModal',
      function ($scope,
      $api: ApiService,
      ENUMS,
      $counteroffer,
      $clickOk,
      REQUEST_STATES,
      $cloudinary,
      $timeout,
      addClientsModal,
      $seo,
      unwrapError,
      messageService: MessageService,
      $scrollService,
      toast: ToastService,
      proposalContactsModal,
      proposalHelpModal,
      adminManageModal,
      $user,
      $location,
      messageAttachmentsModal,
      attachmentsModal) {
      $scope.emitter = new StateEmitter(
        ['LOADING', 'ERROR', 'LOADED'],
        'LOADING');
      $scope.emitter.$waitFor('LOADED', () => {
        $scope.$emit('$viewReady', 'HIDE_FOOTER');
      });
      $scope.spaceAvailability = null;
      $scope.times = getTimeSelectOptions();
      $scope.delta = {};
      $scope.REQUEST_STATES = REQUEST_STATES.host;

      $scope.showEdit = showEdit;
      $scope.load = load;
      $scope.message = message;
      $scope.accept = accept;
      $scope.decline = decline;
      $scope.handleCounterofferResponse = handleCounterofferResponse;
      $scope.hadEvent = $counteroffer.hadEvent;
      $scope.missingFields = missingFields;
      $scope.openAddClientsModal = openAddClientsModal;
      $scope.okToHideMessageSection = okToHideMessageSection;
      $scope.getSubmitText = getSubmitText;
      $scope.fullName = UserHelpers.fullName;
      $scope.setConversation = setConversation;
      $scope.scrollToBottom = scrollToBottom;
      $scope.nonDeletedAttachments = nonDeletedAttachments;
      $scope.openFilesTab = openFilesTab;
      $scope.openSidebar = openSidebar;
      $scope.closeSidebar = closeSidebar;
      $scope.hostCheck = hostCheck;
      $scope.scrollLastMessage = scrollLastMessage;
      $scope.messageToSend = { formattedMessage: '', plaintextMessage: '' };
      $scope.openProposalContactsModal = openProposalContactsModal;
      $scope.openProposalHelpModal = openProposalHelpModal;
      $scope.openAdminManageModal = openAdminManageModal;
      $scope.openMessageAttachmentsModal = openMessageAttachmentsModal;
      $scope.messageToSend = { quillFormat: { ops: [] }, message: '' };
      $scope.sent = false;
      $scope.$seo = $seo;
      $scope.getPageTitle = getPageTitle;
      $scope.shouldOpenReceiptModal = false;
      $scope.getAttachment = getAttachments;
      $scope.sendVenueVideo = sendVenueVideo;
      $scope.isSendVideoEnable = isSendVideoEnable;
      $scope.removeAttachment = removeAttachment;

      $scope.uiData = {
        message: null,
        allowMessaging: true,
        attachments: []
      };
      const conversationId = $scope.conversation;
      $scope.msgData = {
        clientFirstName: '',
        eventRequestDate: '',
        SpaceName: '',
        fbMin: null,
      };
      $scope.templates = [];
      $scope.options = [],
      $scope.selectedKey,
      $scope.allAttachments = [],
      load();

      // Initialization function
      function load() {
        $scope.tab = 'REVIEW';
        $scope.emitter.$state('LOADING');

        $scope.uiManager = {};
        $api.Requests.conversation(conversationId)
        .then((res) => {
          if (res.noRequest) {
            $scope.$emit('REDIRECT', { redirect: `/conversation/${conversationId}/messages` });
            return;
          }

          if (res.redirect) {
            $scope.$emit('REDIRECT', { redirect: '/login' });
            return;
          }
            $scope.conversation = res.conversation;
            $scope.user = $user;
            $scope.request = res.request;
            if ( $scope.request.venue.bookingType && $scope.request.venue.bookingType === 'Defer_to_Venue' ) {
              $scope.templates = VENUE_TEMPLATES['VENUE_OFFLINE_MESSAGE'];
            }
            else {
              $scope.templates = VENUE_TEMPLATES['STANDARD_VENUE_MESSAGE'];
            }
            $scope.templates.map(template => $scope.options.push(template.title));
            createMsgData(res.request);

            if (!$scope.user.isHostOrAdmin()) {
              if (AuthorizedClientHelpers.allowedToView({ doc: $scope.request, user: $scope.user.$ })) {
                $scope.$emit('REDIRECT', { redirect: `/client/conversation/${$scope.conversation._id}` });
              } else {
                $scope.$emit('REDIRECT', { redirect: `/unauthorized/` });
              }
              $scope.request = null;
              return;
            }

            // open receipt modal if it's opened from the email link to add receipt.
            $scope.shouldOpenReceiptModal = $location.search().openReceiptModal === 'true' ? true : false;

            $scope.uiData.allowMessaging = $scope.conversation.admin.visibility.client && $scope.conversation.admin.visibility.venue;

            $scope.client = AuthorizedClientHelpers.primaryEventContact($scope.request).user;

            messageService
              .markConversationRead({ conversation: $scope.conversation })
              .catch(unwrapError);

            $seo.set(getPageTitle());

            $scope.assignee = get($scope, 'request.admin.assignee');
              // create clone to show pricing for most recent version of request
            createClone($scope.request);
            $scope.coverImageUrl = $cloudinary.fetchAndConvertURL(get($scope, 'clone.coverImageUrl'), 'small');
            $scope.coverImageSrcSet = $cloudinary.srcSet(get($scope, 'clone.coverImageUrl'));
            $scope.messages = res.messages || [];
            // calling attachments
            $scope.allAttachments = $scope.getAttachment({ conversation : res.conversation, request: res.request });
            return fetchVenue($scope.request);
          })
          .catch((error) => {
            if (error.status === status.UNAUTHORIZED) {
              $scope.$emit('REDIRECT', { redirect: `/unauthorized/` });
              unwrapError(error);
            }
          });
        }

      function fetchVenue (request: RawBookingRequest) {
        return $api.Venues.get(request.venue.slug,
          {
            menus: true,
            drinks: true,
            getNonVisible: false,
            profileOwner: true,
            _id: request.venue._id
          })
          .then((res) => {
            const { venue, drinks, menus } = res.data.data;
            $scope.venue = venue;
            $scope.drinks = drinks;
            $scope.menus = menus;
            if ($scope.user.isHost() && !roleChecker.isAuthorizedVenueUser({
              user: $scope.user.$,
              venue: $scope.venue
            })) {
              $scope.$emit('REDIRECT', { redirect: `/unauthorized/` });
              return;
            }
            $scope.emitter.$state('LOADED');
            $scope.$emit('venueConversation', $scope.conversation);
            $timeout(() => scrollLastMessage(1));
          })
          .catch(error => unwrapError(error));
      }

      // Listeners

      /**
       * Sync up request with child request
       */
      $scope.$on('REQUEST_CHANGE', (ev, params) => {
        $scope.request = params.request;
        createClone($scope.request);
      });

      $scope.$on('TOP_DOWN_REQUEST_CHANGE', (ev, params) => {
        ev.stopPropagation();
        $scope.request = params.request;
        createClone($scope.request);
        $scope.$broadcast('REQUEST_CHANGE', { request: $scope.request });
      });

      $scope.$on('PARTIAL_REQUEST_CHANGE', (ev, params) => {
        set($scope.request, params.path, params.value);
        createClone($scope.request);
        $scope.$broadcast('REQUEST_CHANGE', { request: $scope.request });
      });

      $scope.$on('UPDATE_MESSAGES', (ev, params) => params.messages ? $scope.messages = params.messages : '');
      $scope.$on('UPDATE_ATTACHMENTS', (ev, params) => params.attachments ? $scope.allAttachments = params.attachments : []);

      function scrollToBottom () {
        $scrollService('#finalize-box');
      }

      function getPageTitle () {
        const contact = get($scope.client, 'company.name') || $scope.client.fullName;

        const proposalState = ENUMS.bookingRequestState.bookedStates.includes($scope.request.state) ? 'Contract' : 'Proposal';

        return `${proposalState} for ${contact}`;
      }

      function openProposalContactsModal() {
        return proposalContactsModal({
          request: $scope.request,
          conversation: $scope.conversation,
          venue: $scope.venue,
          sixplusContacts: true,
          previewUser: false
        });
      }

      function createMsgData(request) {
        const pdm = $scope.user.$;
        const primaryClient = request.clients.filter(client => client.isPrimary === true);
        $scope.msgData.clientFirstName = primaryClient[0].user.profile.name.first;
        $scope.msgData.eventRequestDate = moment(request.data.date).format('dddd, MMM Do, YYYY');
        $scope.msgData.SpaceName = get(request, 'venue.data.spaces[0].data.name');
        $scope.msgData.venueName = request.venue.data.name;
        const eventInDays = moment(request.data.date).diff(moment(), 'days');
        $scope.msgData.eventInDays = `${eventInDays} ${eventInDays > 1 ? 'days' : 'day'}`;
        $scope.msgData.pdm = pdm.firstName;
        $scope.msgData.userIsHostOrAdmin = pdm.roles.includes('Host') || pdm.roles.includes('Admin');
        $scope.msgData.menuDueDate = moment(request.data.date).subtract(request.venue.data.terms.daysToFinalizeMenu, 'd').format('ddd. MMMM Do, YYYY');
        $scope.msgData.groupSize = get(request.data, 'groupSize');
        $scope.msgData.link = `${location.origin}/review/event/${request.conversation}`;
      }

      function openProposalHelpModal() {
        return proposalHelpModal({
          request: $scope.request,
          venue: $scope.venue
        });
      }

      function openAdminManageModal() {
        return adminManageModal({
          request: $scope.request,
          conversation: $scope.conversation,
          user: $scope.user
        }).then((res => window.location.reload()));
      }

      function openMessageAttachmentsModal() {
        return messageAttachmentsModal({
          conversation: $scope.conversation,
          attachments: $scope.uiData.attachments
        })
          .then((data) => {
            $scope.uiData.attachments = get(data, 'value.attachments', $scope.uiData.attachments);
          })
          .catch(error => this.unwrapError(error));
      }

      function openAddClientsModal() {
        addClientsModal({ request: $scope.request, conversation: $scope.conversation })
          .then((data) => {
            if (get(data, 'value.cancel')) {
              return;
            }
            const request = get(data, 'value.request');

            if (request) {
              $scope.request.clients = get(request, 'clients');
              createClone($scope.request);
            }
          })
          .catch(unwrapError);
      }

      function showEdit(request) {
        if (!request) {
          return;
        }
        return includes(ENUMS.bookingRequestState.hostCanCounteroffer, request.state);
      }

      function createClone(request) {
        $scope.clone = $counteroffer.createClone(request);

        const lastCounteroffer: any = last($scope.request.counteroffers);
        if (lastCounteroffer && (lastCounteroffer.state === 'INIT' || lastCounteroffer.state === 'READY')) {
          each(lastCounteroffer.delta, (delta) => {
            $scope.delta[delta.path] = delta;
          });
        }
        $scope.costBreakdownCents = fullPriceBreakdownCents($scope.clone);
        $scope.timeSpan = $counteroffer.displayTime($scope.clone, $scope.delta);

      }

      function scrollLastMessage (time = 500) {
        const lastItem = $('.message-item').last();
        const offset = 0;
        const container = 'conversation-messages';
        $timeout(() => $scrollService(lastItem, offset, time, container));
      }

      function getSubmitText() {
        if ($scope.request) {
          return $scope.request.state === 'CONCLUDED'
            ? 'Reconcile event and Submit Charges'
            : 'Send to client for approval';
        }
      }

      /**
       * Make sure request has required fields before allowing submit
       *
       * @param {Request} request, required
       * @return {Boolean}
       */
      function missingFields(request): any {
        if (!request) {
          return;
        }

        // First require space
        if (!BookingRequestHelpers.selectedSpace(request)) {
          return { reason: 'Please select a space' };
        }

        if (!request.data.groupSize) {
          return { reason: 'Please enter a group count' };
        }

        if (!request.data.duration) {
          return { reason: 'Please select a duration' };
        }

        if (!isNumber(request.data.time)) {
          return { reason: 'Please select a start time' };
        }

        if (!isNumber(BookingRequestHelpers.fbMinCents(request))) {
          return { reason: 'Please enter an F&B Minimum' };
        }
        return BookingRequestHelpers.selectedSpace(request) &&
          request.data.groupSize &&
          request.data.duration &&
          isNumber(request.data.time);
      }

      function message() {
        if ($scope.messageToSend.message === '') {
          toast.badNews('Please enter a message');
          return;
        }
        const messageAttachment = $scope.uiData.attachments.filter( attach => attach.file );
        $scope.sending = true;
        $scope.emitter.$waitFor('LOADED', () => {
          $api.Conversations.sendMessage({
            conversation: $scope.conversation,
            attachments: messageAttachment,
            data: $scope.messageToSend,
            options: { messageAs: 'venue' }
          })
            .then((data) => {
              $scope.sent = true;
              $scope.messageToSend = { formattedMessage: '', plaintextMessage: '' };
              handleNewMessage(data.message);
              $scope.uiData.message = '';
              $scope.sending = false;
              $scope.scrollLastMessage();
              $scope.selectedKey = '';
              $scope.$broadcast('MESSAGE_SENT', { message: 'Your message was sent' });
            })
            .catch((error) => {
              $scope.sending = false;
             unwrapError(error);
            });
        });
        $scope.sent = false;
      }

      function accept(message, resolutionTransaction, attachments) {
        return $scope.emitter.$waitFor('LOADED', () => {

          // If request is a proposal, we need to go down a different logic path
          if ($scope.request.state === 'PROPOSAL') {
            return _acceptProposal({ message, attachments });
          }

          // If request is in concluded state, we need to go down the path to close the event
          if ($scope.request.state === 'CONCLUDED') {
            return _closeEvent(resolutionTransaction);
          }

          const predeposit = $scope.request.state === 'REQUEST' || $scope.request.state === 'COUNTEROFFER';

          return $api.Requests.accept({
            id: $scope.request._id,
            message,
            attachments
          })
            .then((response) => {
              let messageToHost = '';
              const requestData = response.data.request;
              if (requestData && requestData.contractAttachments && requestData.contractAttachments.length || requestData.contractUrl && requestData.contractUrl.length) {
                messageToHost = `Thank you for accepting the request and sending the client a contract. If you need to share an updated version of the contract, please scroll down below the Venue Terms & Conditions section where you can add updated versions of your contract. The client will be notified by email each time you do this. You also will be notified by email, if the client messages you.`;
              } else if (predeposit) {
                messageToHost = `Your proposal has been sent to the client for consideration. They will have a chance to review it, send questions, request any changes, and make a deposit. You can update this proposal as needed and send changes to the client.`;
              } else {
                messageToHost = `Thank you for making alterations and sending them to the client. Feel free to  continue making any further changes. They will be applied automatically. You'll be notified by email if the client messages you and when they have accepted the latest changes.`;
              }
              return _handleRequestResponse(response, messageToHost);
            })
            .then(() => {
              const messageToHostSlider = predeposit ?
                `You've accepted the guest's Event Request.` :
                `You've sent over your alterations to the guest.`;
              _showRequestDecision(messageToHostSlider);
              scrollLastMessage();
            })
            .catch(unwrapError);
        });
      }

      function decline(terminationReason: string, message: {message: 'string', quillFormat: QuillInsert[]}, requestTerminationState: string, blockCalendar: boolean, attachments: TransmittedAttachment[]) {
        return $api.Requests.venueDecline({
          requestId: $scope.request._id,
          data: message,
          attachments,
          requestTerminationState,
          blockCalendar,
          terminationReason
        })
          .then((response) => {
            const messageToHost = `
              You have declined the request. We've sent an email to the guest to let them know of your decision.`;
            return _handleRequestResponse(response, messageToHost);
          })
          .then(() => {
            const messageToHostSlider = `You've declined the guest's Event Request`;
            _showRequestDecision(messageToHostSlider);
          })
          .catch(unwrapError);
      }

      function handleCounterofferResponse(res) {
        $scope.request = res.data.request;
        $scope.conversation = res.data.conversation;
        if (res.data.message) {
          handleNewMessage(res.data.message);
        }
        $scope.tab = 'REVIEW';
        $scope.$broadcast('COUNTEROFFER_SUBMITTED');
      }

      function setConversation(conversation) {
        $scope.conversation = conversation;
      }

      function okToHideMessageSection(request, conversation) {
        if (request && conversation) {
          return request.state === 'INCOMPLETE' && !get(conversation, 'flags.isInquiry');
        }
      }
      // Private Functions

      /**
       * Close an event and submit charges to client. If no charges to submit, just close it out.
       *
       * @param { paymentType: string, note: string, amountCents: number, date: Date, method: string } resolutionTransaction
       */
      function _closeEvent(resolutionTransaction) {
        return $api.Admin.Payment.reconcile({ request: $scope.request, paymentOptions: resolutionTransaction })
          .then((response) => {
            const msg = get(response, 'refund') === 'manual'
              ? 'The event has been reconciled, but a refund must be processed MANUALLY for the client prior to closing this event.'
              : (get(response, 'refund') === 'notManual' ? 'The event has been reconciled successfully.'
              : `The event has been reconciled. If there were any charges, please make sure to handle them manually as soon as possible, if you haven't already.`);
            return _handleRequestResponse({ 'data.request': response.request, 'data.conversation': response.request.conversation }, msg);
          })
          .catch((error) => {
            toast.badNews('Reconciliation payment failed', get(error, 'data.error.message'));
            unwrapError(error);
          });
      }

      /**
       * Accept a proposal as the host
       *
       * @param {String} message
       * @return {Promise}
       * @fires `REQUEST_DECISION`
       */
      function _acceptProposal({ message, attachments }) {
        return $api.Requests.acceptProposal({
          id: $scope.request._id,
          message,
          attachments
        })
          .then((response) => {
            const messageToHost = `Your proposal has been finalized and has been sent over to the guest for consideration. They'll have a chance to approve it and/or send you messages about it. We'll notify you in either case.`;
            return _handleRequestResponse(response, messageToHost);
          })
          .then(() => {
            const messageToHostSlider = 'Your proposal was sent to the guest';
            return _showRequestDecision(messageToHostSlider);
          })
          .catch(unwrapError);
      }

      /**
       * Handle the response from when a host takes an action on a request/proposal
       *
       * @param {Response} response, response from server
       * @param {String} message
       * @return {Promise}
       */
      function _handleRequestResponse(response, message) {
        $scope.request = Object.assign(get(response, 'data.request'), { clients: $scope.request.clients });
        createClone($scope.request);
        if (get(response, 'data.conversation')) {
          $scope.conversation = get(response, 'data.conversation');
        }
        if (get(response, 'data.message')) {
          handleNewMessage(get(response, 'data.message'));
        }
        return $clickOk(message)
          .then(() => {
            $scope.$broadcast('REQUEST_CHANGE', { request: $scope.request });
          });
      }

      /**
       * Bring slider down to notify host of request decision
       *
       * @param {String} messageToHost
       * @fires `REQUEST_DECISION`
       */
      function _showRequestDecision(messageToHost) {
        $scope.$broadcast('REQUEST_DECISION', { message: messageToHost });
        $scope.scrollToTop();
      }

      function nonDeletedAttachments (conversation, request) {
        let fileCount = 0 ;
        if (conversation && conversation.attachments && conversation.attachments.length) {
          fileCount += conversation.attachments.filter(attachment => !attachment.isDeleted && attachment.file.title !== 'Link').length;
        }
        return fileCount ;
      }

      function handleNewMessage (message) {
        $scope.messages = [message, ...$scope.messages];

        if (message.attachments) {
          $scope.conversation.attachments = [...$scope.conversation.attachments, ...message.attachments];
        }

        $scope.uiData.attachments = [];
      }

      function openSidebar () {
        $scope.openSidebarMessage = true;
        $scope.closeSidebarMessage = false;
      }

      function closeSidebar () {
        $scope.closeSidebarMessage = true;
        $scope.openSidebarMessage = false;
      }

      function hostCheck ( user ) {
        if (user.$.roles.includes('Host') || !user.$.roles.includes('Admin')) {
          return true;
        }
        else {
          return false;
        }
      }

      function openFilesTab () {
        $scope.tab = 'FILES';
        $timeout(() => {
          $scrollService('div.dashboard-title');
        });
      }

      function isSendVideoEnable () {
        const venueVideosList = get($scope.request.venue.data, 'videos');
        if (venueVideosList) {
          return venueVideosList.find(video => video.title === 'Full') ? true : false;
        }
        return false;
      }

      function sendVenueVideo () {
        if (!$scope.isSendVideoEnable()) {
          toast.badNews('A full video for this venue is currently unavailable.');
          return;
        }
        $scope.selectedKey = 'Send Venue Video';
        const venueVideosList = get($scope.request.venue.data, 'videos');
        if (venueVideosList) {
          let videoUrl = '';
          for (const video of venueVideosList) {
            if (video.title === 'Full') {
              videoUrl = video.url;
            }
          }
          $scope.uiData.attachments = [{
            conversation: get($scope.conversation, '_id'),
            file: {
              title: 'Venue-video',
              url: videoUrl
            }
          }];
        }
      }

      function removeAttachment (url) {
        $scope.uiData.attachments = $scope.uiData.attachments.filter(attachment => attachment.file.url !== url);
        event.preventDefault();
      }
    }]
  };
};
