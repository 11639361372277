import { RecoRequestService } from './reco-request.service';
import get from 'lodash/get';

// External Dependencies

export default ['ngDialog', function(ngDialog) {
  return function (params: { origin: string, menus: any[], drinks: any[], space: any, venue: any, conversation: any, recommendation: any, lead: any, request: any }) {
    return ngDialog.open({
      preCloseCallback: function(value) {
        /**
         * SKIP SCENARIOS
         * If the request has been submitted, dont show the modal;
        */
        if ((get(value, 'request.state') === 'REQUEST') || (get(value, 'status') === 'Lite')) {
          return Promise.resolve();
        }
        return ngDialog.openConfirm({
          className: 'ngdialog-theme-small',
          controller: ['$scope', 'recoRequestService', function($scope, recoRequestService: RecoRequestService) {
            const data = recoRequestService.getData();
            const { origin, recommendation, request } = data;

            if (!recommendation && !request) {
              $scope.case = 'CASE_1';
            } else if (origin === 'conversation' || origin === 'conversation-continued') {
              $scope.case = 'CASE_3';
            } else if (recommendation) {
              $scope.case = 'CASE_2';
            } else {
              $scope.case = 'CASE_1';
            }
          }],
          template: require('./reco-request-cancel.jade'),
          plain: true
        });
      },
      template: `<reco-request
        request="$ctrl.request",
        conversation="$ctrl.conversation"
        recommendation="$ctrl.recommendation"
        lead="$ctrl.lead"
        space="$ctrl.space"
        venue="$ctrl.venue"
        menus="$ctrl.menus"
        drinks="$ctrl.drinks"
        origin="$ctrl.origin"
        close="$ctrl.close({ request, status, lead })"
      ></reco-request>`,
      className: 'ngdialog-theme-plain-noclose',
      overlay: true,
      plain: true,
      controllerAs: '$ctrl',
      controller: ['$scope', function ($scope) {
        if (params) {
          const { origin, space, venue, recommendation, conversation, lead, menus, drinks, request } = params;
          this.space = space;
          this.venue = venue;
          this.conversation = conversation;
          this.recommendation = recommendation;
          this.lead = lead;
          this.menus = menus;
          this.drinks = drinks;
          this.origin = origin;
          this.request = request;
        }

        this.close = (data) => {
          return $scope.closeThisDialog(data);
        };
      }]
    }).closePromise;
  };
}];
